import React, { useEffect } from 'react'
import DesignSuite2023 from '../../../../../components/DesignSuite2023'
import * as customInput from './CustomInput'
import * as helpers from '../../../helpers'
import ExCodesDisplay from '../../ExCodesDisplay'
import { ShapeExCodeOpt } from '../../../types'
import { /*safeSumCol,*/ trackValidations } from './index'
import { props } from './837p'
import CustomDateInput from './CustomDateInput'
import statusDot from '../../StatusDot'
import DisplayOrPickLinkedBundle from './DisplayOrPickLinkedBundle'
import { AdjStatuses } from '../../Status'
import Calculator from './Calculator'

export default function Table837I({
  newClaim = false,
  readOnly = false,
  isReversal = false,
  elmntCheckAll,
  fnMakeRowCheckbox,
  lines,
  fnGetCellClass,
  fnTrackChange,
  refresh,
  onAssignLineExCode,
  handleClearExCode,
  diagnosisCodes = [],
  Actions,
  setValidations,
  targetedZClaimLineID,
  claimStatus,
  summary,
  calculations,
  sourceType,
}: props): React.ReactElement {
  let amntBilledIsDisabled = true
  if (
    sourceType === helpers.SourceTypes.SourceTypeManual ||
    sourceType === helpers.SourceTypes.SourceTypeClone
  ) {
    amntBilledIsDisabled = false
  }
  if (readOnly) {
    amntBilledIsDisabled = true
  }

  // Compute validations on every change to a line field
  useEffect(() => {
    const vs = {} as trackValidations
    for (let memID in lines) {
      vs[memID] = vs[memID] || {}
      fieldLoop: for (let field in lines[memID]) {
        // @ts-ignore
        switch (field) {
          case 'DiagnosisCode1':
          case 'DiagnosisCode2':
          case 'DiagnosisCode3':
          case 'DiagnosisCode4':
            if (!lines[memID][field]) {
              continue fieldLoop
            }
            if (
              !((lines[memID][field] as any) <= (diagnosisCodes?.length || 0))
            ) {
              vs[memID][field] = true
            }
            break

          case 'ServiceDateStart':
          case 'ServiceDateEnd':
            if (!lines[memID][field]) {
              continue fieldLoop
            }
            if (lines[memID][field] === '_invalid_') {
              vs[memID][field] = true
            }
            break

          default:
            break
        }
      }
    }
    setValidations(vs)
  }, [lines, diagnosisCodes])

  // In some cases, when one field changes (ie. AmntAllowed), we want it to propagate the
  // value to another field as well
  function doTrackChange(memID: string, field: string, cv: any) {
    fnTrackChange(memID, field, cv)

    if (field === 'AmntAllowed') {
      fnTrackChange(memID, 'AmntPayable', cv)
      let disc = (
        Number.parseFloat(lines?.[memID]?.['AmntBilled'] || '') -
        Number.parseFloat(cv)
      ).toFixed(2) as any
      if (cv === null || isNaN(disc)) {
        disc = null
      }
      fnTrackChange(memID, 'AmntPPODiscount', disc)
    }
  }

  return (
    <table>
      <thead>
        <tr>
          <th
            className="center"
            style={{ width: '1%', background: 'transparent' }}>
            <DesignSuite2023.Tooltip
              title={
                <p style={{ margin: 0, maxWidth: 450 }}>
                  These are "stable identifiers". If line sequence numbers
                  change (say you swap the sequence of lines 1 &amp; 2), then
                  referring to "line #1" in the claim history is misleading.
                  These values will always stay the same for a given line.
                </p>
              }>
              <DesignSuite2023.CommonIcons.IconInfo
                fontSize="small"
                style={{ color: '#888' }}
              />
            </DesignSuite2023.Tooltip>
          </th>
          <th className="center" style={{ width: 30 }}>
            <DesignSuite2023.Tooltip title="Toggle / Untoggle All">
              {elmntCheckAll}
            </DesignSuite2023.Tooltip>
          </th>
          <th style={{ width: 40 }}>
            <DesignSuite2023.Tooltip title="Sequence (Line #)">
              <span>Seq</span>
            </DesignSuite2023.Tooltip>
          </th>
          <th style={{ width: 110 }}>
            <DesignSuite2023.Tooltip title="Service Date Start">
              <span>SvcDateStart</span>
            </DesignSuite2023.Tooltip>
          </th>
          <th style={{ width: 110 }}>
            <DesignSuite2023.Tooltip title="Service Date End">
              <span>SvcDateEnd</span>
            </DesignSuite2023.Tooltip>
          </th>
          <th style={{ width: 50 }}>
            <DesignSuite2023.Tooltip title="UB Revenue Code">
              <span>RevCode</span>
            </DesignSuite2023.Tooltip>
          </th>
          <th style={{ width: 30 }}>
            <DesignSuite2023.Tooltip title="Service ID Qualifier">
              <span>SQ</span>
            </DesignSuite2023.Tooltip>
          </th>
          <th style={{ width: 65 }}>
            <DesignSuite2023.Tooltip title="Procedure Code">
              <span>ProcCode</span>
            </DesignSuite2023.Tooltip>
          </th>
          <th style={{ width: 30 }}>
            <DesignSuite2023.Tooltip title="Modifier Code 1">
              <span>MC1</span>
            </DesignSuite2023.Tooltip>
          </th>
          <th style={{ width: 30 }}>
            <DesignSuite2023.Tooltip title="Modifier Code 1">
              <span>MC2</span>
            </DesignSuite2023.Tooltip>
          </th>
          <th style={{ width: 90 }}>
            <DesignSuite2023.Tooltip title='Service Line Item Amount ("Billed Amount")'>
              <span>BilledAmnt</span>
            </DesignSuite2023.Tooltip>
          </th>
          <th style={{ width: 50 }}>
            <DesignSuite2023.Tooltip title="Unit Quantity">
              <span>Quant</span>
            </DesignSuite2023.Tooltip>
          </th>
          <th style={{ width: 30 }}>
            <DesignSuite2023.Tooltip title="Unit of Measurement Code">
              <span>UC</span>
            </DesignSuite2023.Tooltip>
          </th>
          <th style={{ width: 90 }}>
            <DesignSuite2023.Tooltip title="Unit Rate">
              <span>UnitRate</span>
            </DesignSuite2023.Tooltip>
          </th>
          <th style={{ width: 90 }}>
            <DesignSuite2023.Tooltip title="Non-Covered Service Amount">
              <span>NCSAmnt</span>
            </DesignSuite2023.Tooltip>
          </th>
          <th style={{ width: 90 }}>
            <DesignSuite2023.Tooltip title="$ Allowed Amount">
              <span>AllowedAmnt</span>
            </DesignSuite2023.Tooltip>
          </th>
          <th style={{ width: 90 }}>
            <DesignSuite2023.Tooltip title="$ PPO Discount (computed automatically)">
              <span>PPODisc</span>
            </DesignSuite2023.Tooltip>
          </th>
          <th style={{ width: 90 }}>
            <DesignSuite2023.Tooltip title="$ Payable Amount">
              <span>PayableAmnt</span>
            </DesignSuite2023.Tooltip>
          </th>
          <th>
            <DesignSuite2023.Tooltip title="Ex(planation) Codes">
              <span>ExCodes</span>
            </DesignSuite2023.Tooltip>
          </th>
          <th>
            <DesignSuite2023.Tooltip title="Cost key category represents the 'LIMITS' accumulator this line will count towards (if applicable per the employer plan)">
              <span>Linked CostKey &amp; Category</span>
            </DesignSuite2023.Tooltip>
          </th>
        </tr>
      </thead>
      <tbody>
        {Object.values(lines).map((row) => (
          <tr
            className={`${targetedZClaimLineID === row.ID ? 'targeted' : ''}`}
            key={row._memID}>
            <td>
              <span className="stable-identifier">
                {row.ID >= 1
                  ? //@ts-ignore
                    helpers.UUIDShorty(row?.ZClaimLineUUID)
                  : '(unsaved)'}
              </span>
            </td>
            <td style={{ textAlign: 'center' }}>{fnMakeRowCheckbox(row)}</td>
            <td className={fnGetCellClass(row._memID, 'ServiceLineNumber')}>
              <customInput.Input
                disabled={readOnly}
                value={row.ServiceLineNumber}
                restrictInput={customInput.rgxIntegerOnly}
                numeric
                onChange={(nextVal: string) => {
                  doTrackChange(row._memID, 'ServiceLineNumber', nextVal)
                }}
              />
            </td>
            <td className={fnGetCellClass(row._memID, 'ServiceDateStart')}>
              <CustomDateInput
                disabled={readOnly}
                name="ServiceDateStart"
                value={row.ServiceDateStart}
                setter={({ value }: any) => {
                  doTrackChange(row._memID, 'ServiceDateStart', value)
                }}
              />
            </td>
            <td className={fnGetCellClass(row._memID, 'ServiceDateEnd')}>
              <CustomDateInput
                disabled={readOnly}
                name="ServiceDateEnd"
                value={row.ServiceDateEnd}
                setter={({ value }: any) => {
                  doTrackChange(row._memID, 'ServiceDateEnd', value)
                }}
              />
            </td>
            <td className={fnGetCellClass(row._memID, 'UBRevenueCode')}>
              <customInput.Input
                disabled={readOnly}
                value={row.UBRevenueCode}
                onChange={(nextVal: string) => {
                  doTrackChange(row._memID, 'UBRevenueCode', nextVal)
                }}
              />
            </td>
            <td
              className={fnGetCellClass(
                row._memID,
                'UBProductServiceIdQualifier'
              )}>
              <customInput.Input
                disabled={readOnly}
                value={row.UBProductServiceIdQualifier}
                onChange={(nextVal: string) => {
                  doTrackChange(
                    row._memID,
                    'UBProductServiceIdQualifier',
                    nextVal
                  )
                }}
              />
            </td>
            <td className={fnGetCellClass(row._memID, 'UBProcedureCode')}>
              <customInput.Input
                disabled={readOnly}
                value={row.UBProcedureCode}
                onChange={(nextVal: string) => {
                  doTrackChange(row._memID, 'UBProcedureCode', nextVal)
                }}
              />
            </td>
            <td className={fnGetCellClass(row._memID, 'ModifierCode1')}>
              <customInput.Input
                disabled={readOnly}
                value={row.ModifierCode1}
                onChange={(nextVal: string) => {
                  doTrackChange(row._memID, 'ModifierCode1', nextVal)
                }}
              />
            </td>
            <td className={fnGetCellClass(row._memID, 'ModifierCode2')}>
              <customInput.Input
                disabled={readOnly}
                value={row.ModifierCode2}
                onChange={(nextVal: string) => {
                  doTrackChange(row._memID, 'ModifierCode2', nextVal)
                }}
              />
            </td>
            <td className={fnGetCellClass(row._memID, 'AmntBilled')}>
              <customInput.Input
                disabled={amntBilledIsDisabled}
                value={row.AmntBilled}
                restrictInput={customInput.rgxFloatOnly}
                textPrefix="$"
                onChange={(nextVal: string) => {
                  doTrackChange(row._memID, 'AmntBilled', nextVal)
                }}
              />
            </td>
            <td className={fnGetCellClass(row._memID, 'Units')}>
              <customInput.Input
                disabled={readOnly}
                value={row.Units}
                restrictInput={customInput.rgxFloatOnly}
                onChange={(nextVal: string) => {
                  doTrackChange(row._memID, 'Units', nextVal)
                }}
              />
            </td>
            <td
              className={fnGetCellClass(row._memID, 'UBUnitOfMeasurementCode')}>
              <customInput.Input
                disabled={readOnly}
                value={row.UBUnitOfMeasurementCode}
                onChange={(nextVal: string) => {
                  doTrackChange(row._memID, 'UBUnitOfMeasurementCode', nextVal)
                }}
              />
            </td>
            <td className={fnGetCellClass(row._memID, 'UBUnitRate')}>
              <customInput.Input
                disabled={readOnly}
                value={row.UBUnitRate}
                restrictInput={customInput.rgxFloatOnly}
                textPrefix="$"
                onChange={(nextVal: string) => {
                  doTrackChange(row._memID, 'UBUnitRate', nextVal)
                }}
              />
            </td>
            <td
              className={fnGetCellClass(
                row._memID,
                'UBNonCoveredServiceAmount'
              )}>
              <customInput.Input
                value={row.UBNonCoveredServiceAmount}
                restrictInput={customInput.rgxFloatOnly}
                textPrefix="$"
                disabled
                onChange={(nextVal: string) => {
                  doTrackChange(
                    row._memID,
                    'UBNonCoveredServiceAmount',
                    nextVal
                  )
                }}
              />
            </td>
            <td
              className={fnGetCellClass(row._memID, 'AmntAllowed')}
              style={{ whiteSpace: 'nowrap' }}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  width: '100%',
                  position: 'relative',
                }}>
                <customInput.Input
                  disabled={readOnly}
                  value={row.AmntAllowed}
                  restrictInput={customInput.rgxFloatOnly}
                  textPrefix="$"
                  onChange={(nextVal: string) => {
                    doTrackChange(row._memID, 'AmntAllowed', nextVal)
                  }}
                  style={{ display: 'inline-block' }}
                />
                {row.FeeSchedulePriceID && (
                  <DesignSuite2023.Tooltip title="Auto-priced by fee schedule">
                    <statusDot.Dot color={statusDot.Colors.GREEN} />
                  </DesignSuite2023.Tooltip>
                )}
                <Calculator
                  startingValue={row.AmntAllowed}
                  onResult={(v: string | null) => {
                    doTrackChange(row._memID, 'AmntAllowed', v)
                  }}
                />
              </div>
            </td>
            <td className={fnGetCellClass(row._memID, 'AmntPPODiscount')}>
              <customInput.Input
                disabled
                value={row.AmntPPODiscount}
                textPrefix="$"
                onChange={(nextVal: any) => {
                  doTrackChange(row._memID, 'AmntPPODiscount', nextVal)
                }}
              />
            </td>
            <td className={fnGetCellClass(row._memID, 'AmntPayable')}>
              <customInput.Input
                // disabled ={readOnly}
                disabled
                value={
                  claimStatus === AdjStatuses.denied ? '0' : row.AmntPayable
                }
                textPrefix="$"
                onChange={(nextVal: any) => {
                  doTrackChange(row._memID, 'AmntPayable', nextVal)
                }}
              />
            </td>
            <td>
              {/* @todo: disable showing excodes until line is persisted */}
              <ExCodesDisplay
                isNewClaim={newClaim}
                readOnly={readOnly}
                list={row.LineExCodes || []}
                onExCodeCleared={refresh}
                handleClearExCode={(eco: ShapeExCodeOpt) => {
                  handleClearExCode(eco, row._memID)
                }}
                onExCodeAssigned={(eco: ShapeExCodeOpt) => {
                  onAssignLineExCode(eco, row._memID)
                }}
              />
            </td>
            <td>
              {!newClaim ? (
                <DisplayOrPickLinkedBundle
                  readOnly={readOnly}
                  ZClaimLineObj={row}
                  // @todo: this needs ot be injected by parent.parent componet (manual claim setup),
                  // and next, need to figure out where to display the fee schedules, since
                  // a 'FastView' is not a thing in the manual setup context
                  // onFeeSchedulePriceSelected={(
                  //   lineObj: any,
                  //   fspObj: any
                  // ): void => {
                  //   console.log('onFeeSchedulePriceSelected', lineObj, fspObj)
                  // }}
                />
              ) : (
                <small style={{ whiteSpace: 'nowrap' }}>
                  temporary: claim must be created before assigning price
                </small>
              )}
            </td>
          </tr>
        ))}
      </tbody>
      <tfoot>
        <tr>
          <td colSpan={10}>{Actions}</td>
          <td className={`sumd ${isReversal && 'reversal'}`}>
            {calculations?.sumBilled}
          </td>
          <td>{/* {safeSumCol('Units', lines)} */}</td>
          <td></td>
          <td>{/* ${safeSumCol('UBUnitRate', lines)} */}</td>
          <td>{/* ${safeSumCol('UBNonCoveredServiceAmount', lines)} */}</td>
          <td className={`sumd ${isReversal && 'reversal'}`}>
            {calculations?.sumAllowed}
          </td>
          <td className={`sumd ${isReversal && 'reversal'}`}>
            {calculations?.sumPPODiscount}
          </td>
          <td className={`sumd ${isReversal && 'reversal'}`}>
            {claimStatus === AdjStatuses.denied ? (
              <>$0</>
            ) : (
              calculations?.sumPayable
            )}
          </td>
          <td colSpan={2}></td>
        </tr>
      </tfoot>
    </table>
  )
}
