import React from 'react'
import {
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core'

export enum PayableStatus {
  All = 'all',
  BilledCollected = 'billed_collected',
  BilledUncollected = 'billed_uncollected',
  Unbilled = 'unbilled',
}

export function getPayableStatusLabel(value: PayableStatus): string {
  switch (value) {
    case PayableStatus.All:
      return 'All'
    case PayableStatus.BilledCollected:
      return 'Billed Collected'
    case PayableStatus.BilledUncollected:
      return 'Billed Uncollected'
    case PayableStatus.Unbilled:
      return 'Unbilled'
    default:
      return value
  }
}

export enum PayEntityType {
  All = 'all',
  Organization = 'organization',
  Partner = 'partner',
  BillingEntity = 'billing_entity',
  Employer = 'employer',
}

export function getPayEntityTypeDisplay(value: PayEntityType): string {
  switch (value) {
    case PayEntityType.All:
      return 'All'
    case PayEntityType.Organization:
      return 'Organization'
    case PayEntityType.Partner:
      return 'Partner'
    case PayEntityType.BillingEntity:
      return 'Billing Entity'
    case PayEntityType.Employer:
      return 'Employer'
    default:
      return value
  }
}

export interface payableRecordDetail {
  AccountName: string
  ZClaimID: number
  ZClaimAmount: string
  IsReversal: boolean
}

export interface payableRecord {
  RecordID: string
  PayEntityID: number
  PayEntityName: string
  PayEntityType: PayEntityType
  ClaimCount: number
  PayableStatus: string
  Amount: string
  Details: payableRecordDetail[]
}

interface payableDetailProps {
  data: payableRecord
  doClose?(): void
}

export default function PayableDetail({
  data,
  doClose,
}: payableDetailProps): React.ReactElement {
  onclose = doClose ? doClose : null
  return (
    <Container>
      <Typography variant="body1">Payee Name: {data.PayEntityName}</Typography>
      <Typography variant="body1">Claim Count: {data.ClaimCount}</Typography>
      <Typography variant="body1">
        Payable Status:{' '}
        {getPayableStatusLabel(data.PayableStatus as PayableStatus)}
      </Typography>
      <Typography variant="body1">
        Amount: {formatAmount(data.Amount)}
      </Typography>

      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ZClaim ID</TableCell>
              <TableCell>Account</TableCell>
              <TableCell>Amount</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.Details.map((detail, index) => (
              <TableRow key={index}>
                <TableCell>
                  {detail.ZClaimID}
                  {detail.IsReversal ? ' [reversal]' : ''}
                </TableCell>
                <TableCell>{detail.AccountName}</TableCell>
                <TableCell>{formatAmount(detail.ZClaimAmount)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  )
}

function formatAmount(amount: string): string {
  if (!amount || amount === '0') return '$0.00'
  return `$${amount.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`
}
