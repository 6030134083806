import React, { useEffect, useState } from 'react'
import useQueryParamsGen2 from '../../../hooks/useQueryParamsGen2'
import PageInProcessView from './viewInProcess'
import { useSetFrameHeaderMid } from '../../../components/AppFrame'
import { Button } from '@material-ui/core'

const SUB_VIEW_IN_PROCESS = 'in_process'

export default function PageFunding(): React.ReactElement {
  const { queryData, setQueryData } = useQueryParamsGen2({
    scope: 'zclaims.funding.subview',
  })
  const [subView, setSubView] = useState(
    queryData['subView'] || SUB_VIEW_IN_PROCESS
  )

  useEffect(() => {
    setQueryData({ subView })
  }, [subView])

  useSetFrameHeaderMid(() => {
    return (
      <>
        <Button
          size="small"
          variant={subView === SUB_VIEW_IN_PROCESS ? 'contained' : 'text'}
          onClick={() => setSubView(SUB_VIEW_IN_PROCESS)}>
          In Process
        </Button>
      </>
    )
  }, [subView])

  switch (subView) {
    case SUB_VIEW_IN_PROCESS:
      return <PageInProcessView />
  }

  return <span>Invalid state: try clicking In Process above...</span>
}
