import * as api from '../services/thezerocard/api-helper'

export function getZClaimByID(id: number) {
  return api.get(`/zclaims/claim/${id}`)
}

export function getZClaimHistoryByID(id: number) {
  return api.get(`/zclaims/claim/${id}/history`)
}

export function findZClaims(params: any) {
  return api.search('/zclaims/claim', params)
}

export function findZClaimSourceFiles(params: any) {
  return api.search('/zclaims/source_file', params)
}

export function findUnprocessedZClaimSrcGoZero(params: any) {
  params.filter = { ...params.filter, processed: false }
  return api.search('/zclaims/src_go_zero', { ...params }).then((res: any) => {
    if (res.error) {
      throw res
    }
    return res
  })
}

export function postZClaimSourceFileIngest(params: any) {
  return api.post('/zclaims/source_file/ingest', params)
}

export function postZClaimSourceFileScanNew() {
  return api.post('/zclaims/source_file/scan')
}

export function postZClaimProcessSources(params: any) {
  return api.post('/zclaims/process_srcs', params)
}

export function postZClaimProcessGoZero(params: any) {
  return api.post('/zclaims/process_go_zero', params).then((res: any) => {
    if (res.error) {
      throw res
    }
    return res
  })
}

export function getViewableSource(zClaimID: number) {
  return api.get(`/zclaims/claim/${zClaimID}/viewable_source`)
}

export function postZClaimCollection(data: any) {
  return api.post(`/zclaims/collection`, data)
}

export function findZClaimCollections(params: any) {
  return api.search('/zclaims/collection', params)
}

export function deleteZClaimCollection(collectionID: number) {
  return api.del(`/zclaims/collection/${collectionID}`)
}

export function deleteZClaimsFromCollection(
  collectionID: number,
  zClaimIDs: number[]
) {
  return api.del(`/zclaims/collection/${collectionID}/claims`, { zClaimIDs })
}

export function getExcodeList() {
  return api.get(`/zclaims/excode_list`)
}

export function postAddClaimExCode(
  zClaimID: number,
  data: { ExCodeOptID: number }
) {
  return api.post(`/zclaims/claim/${zClaimID}/claim_ex_code`, data)
}

export function postAddLineExCode(
  zClaimLineID: number,
  data: { ExCodeOptID: number }
) {
  return api.post(`/zclaims/line/${zClaimLineID}/line_ex_code`, data)
}

// id here is zclaim_ex_code_claim.id, NOT the "ex_code_opt".id
export function postClearClaimExCode(id: number) {
  return api.post(`/zclaims/claim_ex_code/${id}/clear`)
}

// id here is zclaim_ex_code_line.id, NOT the "ex_code_opt".id
export function postClearLineExCode(id: number) {
  return api.post(`/zclaims/line_ex_code/${id}/clear`)
}

export function postAdjudicate(data: any) {
  return api.post(`/zclaims/adjudicate`, data)
}

export function postMoveClaimsToFundingInProcess(data: any) {
  return api.post(`/zclaims/move_to_funding_in_process`, data)
}

export function getZClaimLinesByZClaimID(id: number) {
  return api.get(`/zclaims/claim/${id}/lines`)
}

export function putZClaimLines(zClaimID: number, data: any) {
  return api.put(`/zclaims/claim/${zClaimID}/lines`, data)
}

export function putZClaimLineFeeSchedulePrice(zClaimID: number, data: any) {
  return api.put(`/zclaims/claim/${zClaimID}/line_fee_schedule_price`, data)
}

export function putZClaimFeeSchedule(zClaimID: number, fsID: number) {
  return api.put(`/zclaims/claim/${zClaimID}/fee_schedule`, {
    FeeScheduleID: fsID,
  })
}

export function deleteZClaimLine(zClaimID: number, data: any) {
  return api.del(`/zclaims/claim/${zClaimID}/lines`, data)
}

export function postNewManualZClaim(data: any) {
  return api.post('/zclaims/claim', data)
}

export function putZClaimDiagnosisCodes(zClaimID: number, data: any) {
  return api.put(`/zclaims/claim/${zClaimID}/diagnosis_codes`, data)
}

export function putZClaimProcedureCodes(zClaimID: number, data: any) {
  return api.put(`/zclaims/claim/${zClaimID}/procedure_codes`, data)
}

export function putZClaimDetail(zClaimID: number, data: any) {
  return api.put(`/zclaims/claim/${zClaimID}/detail`, data)
}

export function getZClaimAssignmentPatient(zClaimID: number) {
  return api.get(`/zclaims/claim/${zClaimID}/patient_assignment`)
}

export function putZClaimPatientOrSubscriber(zClaimID: number, data: any) {
  return api.put(`/zclaims/claim/${zClaimID}/patient`, data)
}

export function putZClaimClearSuspense(zClaimID: number) {
  return api.put(`/zclaims/claim/${zClaimID}/clear_suspense`)
}

export function postZClaimClone(zClaimID: number, data: any) {
  return api.post(`/zclaims/claim/${zClaimID}/clone`, data)
}

//////
export function getZClaimAssignmentProvider(zClaimID: number) {
  return api.get(`/zclaims/claim/${zClaimID}/provider_assignment`)
}

export function putZClaimAssignBillingEntity(zClaimID: number, data: any) {
  return api.put(`/zclaims/claim/${zClaimID}/billing_entity`, data)
}

export function putZClaimAssignPracticeFacility(zClaimID: number, data: any) {
  return api.put(`/zclaims/claim/${zClaimID}/practice_facility`, data)
}

////////////// Src records and defaulters management
export function getZClaimInspectSrcRecords(params: any) {
  return api.search(`/zclaims/inspect_src_records`, params)
}

export function getZClaimSrcRecord(params: any) {
  return api.get(`/zclaims/src_record`, null, params)
}

export function putZClaimSrcRecordDefaulterEmployerMember(params: any) {
  return api.put(`/zclaims/src_record_defaulter_employer_member`, params)
}

export function putZClaimSrcRecordDefaulterBillingEntity(params: any) {
  return api.put(`/zclaims/src_record_defaulter_billing_entity`, params)
}

export function putZClaimSrcRecordDefaulterPracticeFacility(params: any) {
  return api.put(`/zclaims/src_record_defaulter_practice_facility`, params)
}

export function putZClaimAssignVBASource(params: any) {
  return api.put(`/zclaims/assign_vba_source`, params)
}

export function putZClaimAssignUncontractedProvider(params: any) {
  return api.put(`/zclaims/assign_uncontracted_provider`, params)
}
////////////// Src records and defaulters management

export function getZClaimLimitsInfo(zClaimID: number): Promise<any> {
  return api.get(`/zclaims/claim/${zClaimID}/limits_info`)
}

export function getRelatedClaimsByZClaimID(zClaimID: number) {
  return api.get(`/zclaims/claim/${zClaimID}/related_claims`)
}

export function getMembersInfo(params: any) {
  return api.get('/zclaims/members_info', null, params)
}
