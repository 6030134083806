import React, { useState } from 'react'
import DesignSuite2023 from '../../../components/DesignSuite2023'
import { payableRecord, PayEntityType } from './PayableDetail'
import ManagedDateInput from '../../../components/Inputs/managedDateInput'
import useErrorHandlers from '../../../hooks/useErrorHandlers'
import useSnackbar, { SnackbarTypeSuccess } from '../../../hooks/useSnackbar'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from '@material-ui/core'
import {
  renderNotesField as RenderNotesField,
  renderNumericField as RenderNumericField,
  renderSwitchField as RenderSwitchField,
  renderTextField as RenderTextField,
  SetterArgs,
} from '../../../components/Inputs/standard'
import {
  ManualPaymentType,
  PayItem,
  postSendManualPayment,
  PostSendManualPaymentParams,
} from '../../../actions/ZClaimFundingActions'
import styled from 'styled-components'

type props = {
  data: payableRecord
  onClose?(): void
}

const StyledContent = styled.div`
  .autocomplete-root {
    width: auto;
    .MuiFormControl-fullWidth {
      min-width: 0;
    }
  }
`

export default function ManualPay({
  data,
  onClose,
}: props): React.ReactElement {
  const initialPostSendManualPaymentParams: PostSendManualPaymentParams = {
    paymentDate: new Date(),
    manualPayType: ManualPaymentType.CHECK,
    checkNumber: null,
    achInfo: null,
    sendEOP: data.PayEntityType === PayEntityType.BillingEntity,
    notes: null,
    payItems: data.Details.map(
      (detail): PayItem => ({
        zClaimID: detail.ZClaimID,
        payEntityID: data.PayEntityID,
        amount: detail.ZClaimAmount,
      })
    ),
  }

  const [isOpen, setIsOpen] = useState(false)
  const [postSendManualPaymentParams, setPostSendManualPaymentParams] =
    useState<PostSendManualPaymentParams>(initialPostSendManualPaymentParams)

  const { catchAPIError } = useErrorHandlers()
  const { showForDuration: showSnackbar } = useSnackbar()

  const setter = ({ name, value }: SetterArgs) => {
    const newValue =
      name === 'checkNumber' ? (value === null ? null : Number(value)) : value
    setPostSendManualPaymentParams((curr: PostSendManualPaymentParams) => ({
      ...curr,
      [name]: newValue,
    }))
  }

  const handleClose = () => {
    setIsOpen(false)
    onClose?.()
  }

  function doPostManualPayment() {
    let params: PostSendManualPaymentParams = postSendManualPaymentParams

    postSendManualPayment(params)
      .then((res: any) => {
        showSnackbar(`Manual payment posted OK`, SnackbarTypeSuccess)
        onClose?.()
        setIsOpen(false)
      })
      .catch(
        catchAPIError({
          defaultMessage: 'Failed posting manual payment',
        })
      )
  }

  return (
    <>
      <Button
        variant="outlined"
        color="secondary"
        onClick={() => {
          setIsOpen(true)
        }}>
        Manual Pay
      </Button>

      <Dialog open={isOpen} onClose={handleClose} maxWidth={'lg'}>
        <DialogTitle>Manual Pay: {data.PayEntityName}</DialogTitle>
        <DialogContent style={{ width: '1080px' }}>
          <StyledContent>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={3}>
                <ManagedDateInput
                  name="paymentDate"
                  label="Payment Date"
                  value={postSendManualPaymentParams.paymentDate}
                  setter={setter}
                />
              </Grid>

              <Grid item xs={1}></Grid>
              <Grid item xs={3}>
                <Typography variant="h6">
                  {formatAmount(data.Amount)}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <RenderSwitchField
                  name="sendEOP"
                  label="Send EOP"
                  opts={{
                    disabled:
                      data.PayEntityType !== PayEntityType.BillingEntity,
                  }}
                  value={postSendManualPaymentParams.sendEOP}
                  setter={setter}
                />
                {data.PayEntityType !== PayEntityType.BillingEntity && (
                  <>
                    <br />
                    <Typography
                      variant="caption"
                      style={{ fontStyle: 'italic' }}>
                      can only Send EOPs to providers, not {data.PayEntityType}s
                    </Typography>
                  </>
                )}
              </Grid>
              <Grid item xs={2}></Grid>
              <Grid item xs={4}>
                <FormControl>
                  <FormLabel>Type</FormLabel>
                  <RadioGroup
                    row
                    name="manualPayType"
                    value={postSendManualPaymentParams.manualPayType}
                    onChange={(ev: React.ChangeEvent<HTMLInputElement>) => {
                      setter({ name: ev.target.name, value: ev.target.value })
                      if (ev.target.value !== ManualPaymentType.CHECK) {
                        setter({ name: 'checkNumber', value: null })
                      }
                    }}>
                    <FormControlLabel
                      value={ManualPaymentType.ACH}
                      control={<Radio />}
                      label="ACH"
                    />
                    <FormControlLabel
                      value={ManualPaymentType.CHECK}
                      control={<Radio />}
                      label="Check"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={3}>
                <RenderNumericField
                  name="checkNumber"
                  label="Check Number"
                  value={postSendManualPaymentParams.checkNumber || ''}
                  opts={{
                    hidden:
                      postSendManualPaymentParams.manualPayType !==
                      ManualPaymentType.CHECK,
                  }}
                  setter={setter}
                />
                <RenderTextField
                  name="achInfo"
                  label="ACH Info"
                  value={postSendManualPaymentParams.achInfo}
                  opts={{
                    hidden:
                      postSendManualPaymentParams.manualPayType !==
                      ManualPaymentType.ACH,
                  }}
                  setter={setter}
                />
              </Grid>

              <Grid item xs={12}>
                <RenderNotesField
                  name="notes"
                  label="Notes"
                  value={postSendManualPaymentParams.notes}
                  setter={setter}
                />
              </Grid>
            </Grid>
          </StyledContent>
        </DialogContent>

        <DialogActions>
          <DesignSuite2023.GridLR
            left={
              <>
                <Button
                  color="secondary"
                  variant="outlined"
                  size="small"
                  onClick={handleClose}>
                  Cancel
                </Button>
              </>
            }
            right={
              <>
                <Button
                  color="primary"
                  variant="outlined"
                  onClick={doPostManualPayment}>
                  Pay
                </Button>
              </>
            }
          />
        </DialogActions>
      </Dialog>
    </>
  )
}

function formatAmount(amount: string): string {
  if (!amount || amount === '0') return '$0.00'
  return `$${amount.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`
}
