import React, { useState, useEffect } from 'react'
import {
  deleteZClaimsFromCollection,
  deleteZClaimCollection,
} from '../../../../actions/ZClaimActions'
import CollectionPicker from '../../Components/Pickers/CollectionPicker'
import * as ClaimTable from '../../Components/ClaimTable'
import { Button } from '@material-ui/core'
import DesignSuite2023 from '../../../../components/DesignSuite2023'
import ConfirmDialog from '../../../../components/ConfirmDialog'

export default function WorkflowMyCollections(): React.ReactElement {
  const [collectionIDs, setCollectionIDs] = useState<number[]>([])
  const [tblKey, setTblKey] = useState<number>(0)
  const [pickerKey, setPickerKey] = useState<number>(0)
  const [checkedItems, setCheckedItems] = useState<Array<any>>([])

  useEffect(() => {
    setTblKey((curr) => curr + 1)
    setCheckedItems([])
  }, [collectionIDs])

  function doPurgeClaims() {
    if (!checkedItems.length || !collectionIDs.length) {
      return
    }
    const zClaimIDs = checkedItems.map((v) => v.ID)
    deleteZClaimsFromCollection(collectionIDs[0], zClaimIDs).then(() => {
      setTblKey((curr) => curr + 1)
    })
  }

  function doDeleteCollection() {
    if (!collectionIDs.length) {
      return
    }
    ConfirmDialog({
      title: 'Delete Collection',
      content:
        'Are you sure you want to delete this collection? This cannot be undone.',
      onConfirm: () => {
        deleteZClaimCollection(collectionIDs[0]).then(() => {
          setCollectionIDs([])
          setTblKey((curr) => curr + 1)
          setPickerKey((curr) => curr + 1)
        })
      },
    })
  }

  return (
    <div>
      <DesignSuite2023.GridLR
        left={
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div
              style={{
                width: 320,
                display: 'inline-block',
                paddingRight: '0.5rem',
              }}>
              <CollectionPicker
                key={pickerKey}
                passFilters={{ myCollections: true }}
                inputLabel="Existing Collections"
                handleChange={(collectionIDs: number[]) => {
                  setCollectionIDs(collectionIDs)
                }}
                currentCollectionIDs={collectionIDs}
              />
            </div>
            {!!collectionIDs.length && (
              <Button variant="outlined" onClick={doDeleteCollection}>
                Delete Collection
              </Button>
            )}
          </div>
        }
        right={
          <>
            {!!checkedItems.length && (
              <Button variant="outlined" onClick={doPurgeClaims}>
                Remove {checkedItems.length} Claims From Collection
              </Button>
            )}
          </>
        }
      />

      {collectionIDs.length > 0 && (
        <div style={{ marginTop: '-1rem', position: 'relative' }}>
          <ClaimTable.Table
            key={tblKey}
            passFilters={{ collectionIDs }}
            initPageSize={250}
            onCheckHandler={setCheckedItems}
          />
        </div>
      )}
    </div>
  )
}
