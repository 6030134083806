import React, { useState, useEffect } from 'react'
import { TextField, Chip } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import useErrorHandlers from '../../../../hooks/useErrorHandlers'
import { findZClaimCollections } from '../../../../actions/ZClaimActions'
import { ShapeCollection } from '../../types'
import styled from 'styled-components'

const StyledOption = styled.span`
  font-size: 85%;
`

export interface Props {
  currentCollectionIDs: Array<number>
  handleChange: (collections: Array<number>) => void
  passFilters?: any
  getOptionLabel?: (option: ShapeCollection) => string
  getOptionDisabled?: (option: ShapeCollection) => boolean
  multiple?: boolean
  inputLabel?: string
}

export type { ShapeCollection }

/*
  Switching the autocomplete between supporting 'multiple' or singular is a major
  PITA; to make it a little less painful, its easier to make the props exposed
  to this component act as if always multiple (so even if its a single-select, then
  it would still pass `currentCollectionIDs=[<one value>]`)
*/
export default function CollectionPicker({
  currentCollectionIDs = [],
  handleChange,
  passFilters = {},
  getOptionLabel = (option: ShapeCollection & Partial<any>) =>
    option.customLabel, // see promise handler where it decorates customLabel below
  getOptionDisabled = (option: ShapeCollection) => false,
  multiple = false,
  inputLabel = 'Collections',
}: Props) {
  const [allCollections, setAllCollections] = useState<Array<ShapeCollection>>(
    []
  )
  const [selectedCollections, setSelectedCollections] = useState<
    Array<ShapeCollection>
  >([])
  const { catchAPIError } = useErrorHandlers()

  useEffect(() => {
    findZClaimCollections({
      filter: { ...passFilters, disableLimit: true },
      page: 1,
      pageSize: 10,
    })
      .then((res: { Data: Array<ShapeCollection> }) => {
        setAllCollections(
          res.Data.map((c) => ({
            ...c,
            customLabel: c.Name || c.CreatedAt,
          }))
        )
        // setAllCollections(res.Data.map(d => ({
        //   ...d,
        //   // @ts-ignore
        //   customLabel: d.FileKey.split('/').findLast(() => true) || '',
        // })))
      })
      .catch(
        catchAPIError({
          defaultMessage:
            'Failed to fetch Collections; please contact Engineering',
        })
      )
  }, [])

  useEffect(() => {
    const collections = currentCollectionIDs
      .map((bID: number) => {
        return allCollections.find((b: any) => b.ID === bID)
      })
      .filter((v) => !!v) as ShapeCollection[]
    setSelectedCollections(collections)
  }, [currentCollectionIDs, allCollections])

  function onChange(e: any, value: any) {
    if (value === null) {
      setSelectedCollections([])
      handleChange([])
      return
    }
    if (Array.isArray(value)) {
      setSelectedCollections(value)
      handleChange(value.map((b) => b.ID))
      return
    }
    setSelectedCollections([value])
    handleChange([value.ID])
  }

  return (
    <Autocomplete
      multiple={multiple}
      options={allCollections}
      getOptionLabel={getOptionLabel}
      renderOption={(opt: any): any => {
        return <StyledOption>{opt.customLabel}</StyledOption>
      }}
      getOptionDisabled={getOptionDisabled}
      getOptionSelected={(opt: any, value: any) => {
        return opt.ID === value.ID
      }}
      value={multiple ? selectedCollections : selectedCollections[0] || null}
      onChange={onChange}
      renderInput={(params: any) => (
        <TextField
          {...params}
          size="small"
          variant="outlined"
          label={inputLabel}
          placeholder="Search..."
          InputLabelProps={{ shrink: true }}
          style={{ minWidth: 250 }}
        />
      )}
      renderTags={(value: any, getProps: any) =>
        value.map((option: any, index: number) => (
          <Chip
            variant="outlined"
            label={option.customLabel}
            {...getProps({ index })}
          />
        ))
      }
    />
  )
}

export { CollectionPicker as Picker }
