import React, { useState, useEffect } from 'react'
import DesignSuite2023 from '../../../components/DesignSuite2023'
import { postZClaimCollection } from '../../../actions/ZClaimActions'
import useErrorHandlers from '../../../hooks/useErrorHandlers'
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  TextField,
  Typography,
} from '@material-ui/core'
import CollectionPicker from './Pickers/CollectionPicker'

interface props {
  zClaimIDs: Array<number>
}

export default function DialogNewCollection({
  zClaimIDs,
}: props): React.ReactElement | null {
  const [isOpen, setIsOpen] = useState(false)
  const [name, setName] = useState('')
  const [existingCollectionID, setExistingCollectionID] = useState<Array<any>>(
    []
  )
  const { catchAPIError } = useErrorHandlers()

  useEffect(() => {
    if (!isOpen) return
    setName('')
    setExistingCollectionID([])
  }, [isOpen])

  function doClose() {
    setIsOpen(false)
    // onClose && onClose()
  }

  function isDisabled() {
    return (zClaimIDs || []).length === 0
  }

  function doCreateCollection() {
    postZClaimCollection({
      name,
      existingCollectionID: existingCollectionID[0] || null,
      zClaimIDs,
    })
      .then((res: any) => {
        setIsOpen(false)
      })
      .catch(
        catchAPIError({
          defaultMessage: 'Failed creating collection',
        })
      )
  }

  return (
    <>
      <DesignSuite2023.Tooltip title="Add the selected claims to a collection. Any given claim may only be in one collection at a time.">
        <Button
          fullWidth
          variant="outlined"
          color="primary"
          size="small"
          onClick={setIsOpen.bind(null, true)}>
          Add To Collection
        </Button>
      </DesignSuite2023.Tooltip>

      <Dialog open={isOpen} maxWidth="sm">
        <DialogTitle style={{ paddingBottom: '0.5rem' }}>
          New Collection
        </DialogTitle>
        <DialogContent style={{ paddingTop: 0 }}>
          <Typography variant="body2" style={{ marginBottom: '0.75rem' }}>
            Add the selected claims to an existing collection:
          </Typography>

          <CollectionPicker
            // multiple
            passFilters={{ myCollections: true }}
            inputLabel="Existing Collections"
            handleChange={(collectionIDs: number[]) => {
              setExistingCollectionID(collectionIDs)
              setName('')
            }}
            currentCollectionIDs={existingCollectionID}
          />

          <Typography variant="body2" style={{ margin: '1rem 0 0.5rem' }}>
            ...or create a new collection by simply putting a name in the field
            below:
          </Typography>

          <div>
            <TextField
              fullWidth
              size="small"
              variant="outlined"
              value={name}
              placeholder="Name"
              onChange={(ev: any) => {
                setName(ev.target?.value || '')
                setExistingCollectionID([])
              }}
            />
          </div>

          {/* <DesignSuite2023.Divider />
          <Typography variant="caption">
            If you select existing claims, <i>and</i> give a name to a new
            collection, the claims will be added to both existing and a newly
            created one.
          </Typography> */}
        </DialogContent>

        <DialogActions>
          <Grid container spacing={2} justify="space-between">
            <Grid item xs="auto">
              <Button color="secondary" variant="outlined" onClick={doClose}>
                Cancel
              </Button>
            </Grid>
            <Grid item xs="auto">
              <Button
                disabled={isDisabled()}
                color="primary"
                variant="contained"
                onClick={doCreateCollection}>
                Save
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </>
  )
}
