import React, { useEffect, useState } from 'react'
import useQueryParamsGen2 from '../../../hooks/useQueryParamsGen2'
import DesignSuite2023 from '../../../components/DesignSuite2023'
import WorkflowSourceFiles from './workflows/SourceFiles/WorkflowSourceFiles'
import WorkflowReconcileSourceData from './workflows/ReconcileSourceData'
import WorkflowSuspenseQueue from './workflows/SuspenseQueue'
import WorkflowGoZero from './workflows/GoZero/WorkflowGoZero'
import WorkflowVBA from './workflows/ReconcileVBA'
import WorkflowMyCollections from './workflows/MyCollections'
import WorkflowUtils from './workflows/Utils'
import { canExecEngineering } from '../../../utils/perms'
import styled from 'styled-components'

const StyledWorkflowsWrapper = styled.div`
  .sublayout {
    display: flex;
    width: 100%;

    .left {
      min-width: 240px;
      align-self: flex-start;
      position: sticky;
      top: calc(60px + 1rem);
      padding-right: 1.5rem;
      z-index: 11;
    }

    .right {
      flex: 1;
      overflow: clip;
      overflow-clip-margin: 1rem;

      .std-table {
        .header-items {
          margin-top: -0.5rem;
        }
      }
    }
  }
`

export default function PageWorkflowsView(): React.ReactElement {
  const { queryData, setQueryData } = useQueryParamsGen2({
    scope: 'zclaims.workflows.tabs',
  })
  const [tab, setTab] = useState<string>(queryData['tab'] || 'sus')
  const [canEngeeringOnly] = useState<boolean>(canExecEngineering())

  useEffect(() => {
    setQueryData({ tab })
  }, [tab])

  return (
    <StyledWorkflowsWrapper>
      <DesignSuite2023.StyledTabs
        variant="fullWidth"
        value={tab}
        style={{ margin: '0 0 1rem', borderRadius: '8px' }}>
        <DesignSuite2023.StyledTab
          value={'sus'}
          label="Suspense Queue"
          onClick={() => setTab('sus')}
        />
        <DesignSuite2023.StyledTab
          value={'my_collections'}
          label="My Collections"
          onClick={() => setTab('my_collections')}
        />
        <DesignSuite2023.StyledTab
          value={'rsd'}
          label="Reconcile Source Data"
          onClick={() => setTab('rsd')}
        />
        <DesignSuite2023.StyledTab
          value={'goz'}
          label="GoZero"
          onClick={() => setTab('goz')}
        />
        <DesignSuite2023.StyledTab
          value={'csf'}
          label="Claim Source/Files"
          onClick={() => setTab('csf')}
        />
        <DesignSuite2023.StyledTab
          value={'cag'}
          label="Problematic Claims"
          onClick={() => setTab('cag')}
        />
        <DesignSuite2023.StyledTab
          value={'vba'}
          label="VBA (Legacy) Claims"
          onClick={() => setTab('vba')}
        />
        {canEngeeringOnly && (
          <DesignSuite2023.StyledTab
            value={'utils'}
            label="Dev Utils"
            onClick={() => setTab('dev_utils')}
          />
        )}
      </DesignSuite2023.StyledTabs>

      {tab === 'rsd' && <WorkflowReconcileSourceData />}
      {tab === 'csf' && <WorkflowSourceFiles />}
      {tab === 'sus' && <WorkflowSuspenseQueue />}
      {tab === 'goz' && <WorkflowGoZero />}
      {tab === 'vba' && <WorkflowVBA />}
      {tab === 'my_collections' && <WorkflowMyCollections />}
      {tab === 'dev_utils' && canEngeeringOnly && <WorkflowUtils />}
    </StyledWorkflowsWrapper>
  )
}
