import {
  getInvoiceReceiptDetail,
  receiptType,
} from '../../../actions/ZClaimFundingActions'
import React, { useEffect, useState } from 'react'
import {
  Card,
  CardContent,
  CardHeader,
  Chip,
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core'
import useErrorHandlers from '../../../hooks/useErrorHandlers'
import styled from 'styled-components'

interface appliedReceipt {
  ReceiptID: number
  ReceiptDate: Date
  ReceiptType: receiptType
  CheckNumber: string
  Notes: string
  ReceiptAmount: string
  AppliedAmount: string
}

interface invoiceInfo {
  InvoiceID: number
  EmployerID: number
  EmployerName: string
  InvoiceNumber: number
  InvoiceDate: Date
  InvoiceAmount: string
  AppliedAmount: string
  OpenBalance: string
  AppliedReceipts: appliedReceipt[]
}

interface invoiceReceiptDetailProps {
  invoiceID: number
  doClose?(): void
}

const DetailDiv = styled.div`
  margin-bottom: 16px;
`

export default function InvoiceReceiptDetail({
  invoiceID,
  doClose,
}: invoiceReceiptDetailProps): React.ReactElement {
  const { catchAPIError } = useErrorHandlers()
  const [data, setData] = useState<invoiceInfo>({
    InvoiceID: 0,
    EmployerID: 0,
    EmployerName: '',
    InvoiceNumber: 0,
    InvoiceDate: new Date(0),
    InvoiceAmount: '0',
    AppliedAmount: '0',
    OpenBalance: '0',
    AppliedReceipts: [],
  })

  onclose = doClose ? doClose : null

  useEffect(() => {
    getData()
  }, [])

  function getData() {
    return getInvoiceReceiptDetail(invoiceID)
      .then((res: any) => {
        setData(res.Data)
      })
      .catch(
        catchAPIError({
          defaultMessage: 'Failed to get invoice receipt data',
        })
      )
  }

  if (!data) {
    return <></>
  }

  return (
    <>
      <Container>
        <Card variant={'outlined'} style={{ marginTop: '20px' }}>
          <CardHeader title={<div>{data.EmployerName}</div>} />
          <CardContent>
            <DetailDiv>
              <Chip label="Invoice Number" size="small" />
              &nbsp;&nbsp;&nbsp;
              <Typography component="span" variant="body1">
                {data.InvoiceNumber.toString()}
              </Typography>
              &nbsp;&nbsp;&nbsp;
              <Chip label="Invoice Date" size="small" />
              &nbsp;&nbsp;&nbsp;
              <Typography component="span" variant="body1">
                {data.InvoiceDate.toString()}
              </Typography>
              &nbsp;&nbsp;&nbsp;
              <Chip label="Invoice Amount" size="small" />
              &nbsp;&nbsp;&nbsp;
              <Typography component="span" variant="body1">
                {formatAmount(data.InvoiceAmount)}
              </Typography>
              &nbsp;&nbsp;&nbsp;
              <Chip label="Applied Amount" size="small" />
              &nbsp;&nbsp;&nbsp;
              <Typography component="span" variant="body1">
                {formatAmount(data.AppliedAmount)}
              </Typography>
              &nbsp;&nbsp;&nbsp;
              <Chip label="Open Balance" size="small" />
              &nbsp;&nbsp;&nbsp;
              <Typography component="span" variant="body1">
                {formatAmount(data.OpenBalance)}
              </Typography>
              &nbsp;&nbsp;&nbsp;
            </DetailDiv>
            {data.AppliedReceipts && data.AppliedReceipts.length > 0 && (
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Receipt ID</TableCell>
                      <TableCell>Receipt Date</TableCell>
                      <TableCell>Receipt Type</TableCell>
                      <TableCell>Check Number</TableCell>
                      <TableCell>Notes</TableCell>
                      <TableCell>Receipt Amount</TableCell>
                      <TableCell>Applied Amount</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data.AppliedReceipts.map((receipt) => (
                      <TableRow key={receipt.ReceiptID}>
                        <TableCell>{receipt.ReceiptID}</TableCell>
                        <TableCell>{receipt.ReceiptDate}</TableCell>
                        <TableCell>{receipt.ReceiptType}</TableCell>
                        <TableCell>{receipt.CheckNumber}</TableCell>
                        <TableCell>{receipt.Notes}</TableCell>
                        <TableCell>
                          {formatAmount(receipt.ReceiptAmount)}
                        </TableCell>
                        <TableCell>
                          {formatAmount(receipt.AppliedAmount)}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </CardContent>
        </Card>
      </Container>
    </>
  )
}

function formatAmount(amount: string | number): string {
  if (!amount) {
    return `$0.00`
  }

  if (typeof amount === 'number') {
    amount = amount.toString()
  }

  if (amount === '0') {
    return `$0.00`
  }

  return `$${amount.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`
}
