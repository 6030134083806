import React, { useState } from 'react'
import { Table, StandardFilterSearch } from './InvoiceableClaimTable'
import DesignSuite2023, {
  DrawerProps,
} from '../../../components/DesignSuite2023'
import {
  Card,
  CardContent,
  CardHeader,
  Container,
  Typography,
} from '@material-ui/core'
import { invoiceableRecord } from './InvoiceableTable'

type props = DrawerProps & {
  data: invoiceableRecord
}

export default React.forwardRef<props, any>(function (
  { data, disableEscapeKeyDown = true, onClose: propOnClose, ...spread }: props,
  ref: any
): React.ReactElement | null {
  const [component, setComponent] = useState<React.ReactElement | null>(null)

  function onOpen() {
    setComponent(
      <>
        <Container>
          <Card variant={'outlined'} style={{ marginTop: '20px' }}>
            <CardHeader
              title={
                <>
                  <div>{`${data.EmployerName}`}</div>
                  <Typography>{`Claims: ${data.ClaimCount}`}</Typography>
                  <Typography>{`Invoice Total ${formatAmount(data.InvoiceTotal)}`}</Typography>
                </>
              }></CardHeader>
            <CardContent>
              <Table
                passFilters={{ ZClaimIDs: data.ZClaimIDs }}
                dataTableProps={{
                  keyProp: 'ZClaimID',
                }}
                BeforeTable={pageColumnLeft}
              />
            </CardContent>
          </Card>
        </Container>
      </>
    )
  }

  function onClose() {
    setComponent(null)
    propOnClose?.()
  }

  return (
    <DesignSuite2023.Drawer
      {...spread}
      ref={ref}
      width="80vw"
      disableEscapeKeyDown={disableEscapeKeyDown}
      onOpen={onOpen}
      onClose={onClose}>
      {component}
    </DesignSuite2023.Drawer>
  )
})

function formatAmount(amount: string): string {
  if (!amount || amount === '0') return '$0.00'
  return `$${amount.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`
}

const pageColumnLeft = (
  <div className="lefty">
    <div style={{ paddingBottom: '1rem', textAlign: 'right' }}></div>
    <DesignSuite2023.Divider />
    <div style={{ paddingBottom: '1rem' }}>
      <StandardFilterSearch
        autoFocus
        label="Search"
        InputLabelProps={{ shrink: true }}
      />
    </div>
  </div>
)
