import React, { useState } from 'react'
import useErrorHandlers from '../../../hooks/useErrorHandlers'
import useSnackbar, { SnackbarTypeSuccess } from '../../../hooks/useSnackbar'
import DesignSuite2023 from '../../../components/DesignSuite2023'
import { postZClaimClone } from '../../../actions/ZClaimActions'
import {
  Button,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
} from '@material-ui/core'

interface props {
  zClaimID: number
  onComplete?: () => void
}

export default function ClaimReversalDialog({
  zClaimID,
  onComplete,
}: props): React.ReactElement | null {
  const [isOpen, setIsOpen] = useState(false)
  const { catchAPIError } = useErrorHandlers()
  const { showForDuration: showSnackbar } = useSnackbar()

  function doRefundClaimOnly() {
    postZClaimClone(zClaimID, { action: 'refund_only' })
      .then(() => {
        showSnackbar(
          '1 refund claim created and associated to existing claim',
          SnackbarTypeSuccess
        )
        onComplete?.()
      })
      .catch(
        catchAPIError({
          defaultMessage: 'Failed to create refund claim',
        })
      )
      .finally(() => setIsOpen(false))
  }

  function doAdjustClaim() {
    postZClaimClone(zClaimID, { action: 'adjust' })
      .then(() => {
        showSnackbar(
          '1 refund claim and 1 adjustment claim created and associated to existing claim',
          SnackbarTypeSuccess
        )
        onComplete?.()
      })
      .catch(
        catchAPIError({
          defaultMessage: 'Failed to create refund and adjustment claim pair',
        })
      )
      .finally(() => setIsOpen(false))
  }

  return (
    <>
      <DesignSuite2023.Tooltip title="*No immediate action*: opens dialog to determine how to adjust this claim">
        <Button
          className="no-margin"
          size="small"
          variant="outlined"
          color="secondary"
          fullWidth
          onClick={() => setIsOpen(true)}>
          <span>Issue Claim Adjustment</span>
        </Button>
      </DesignSuite2023.Tooltip>

      <Dialog open={isOpen} maxWidth="lg" onClose={() => setIsOpen(false)}>
        <DialogTitle disableTypography style={{ background: '#f1f1f1' }}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}>
            <Typography variant="h6">Issue Claim Adjustment</Typography>
            <div>
              <IconButton
                size="medium"
                onClick={() => setIsOpen(false)}
                style={{ padding: 5 }}>
                <DesignSuite2023.CommonIcons.IconCancel fontSize="inherit" />
              </IconButton>
            </div>
          </div>
        </DialogTitle>

        <DialogContent>
          <div
            style={{
              display: 'grid',
              gridColumnGap: '1rem',
              gridRowGap: '1rem',
              gridTemplateColumns: '1fr auto',
              alignItems: 'center',
              padding: '1rem 0',
            }}>
            <Button variant="outlined" onClick={doRefundClaimOnly}>
              Refund Claim Only
            </Button>
            <Typography variant="body2">
              Copy the existing claim and reverse the full amount.
            </Typography>

            <Button variant="outlined" onClick={doAdjustClaim}>
              Adjust Claim
            </Button>
            <Typography variant="body2">
              Copy the existing claim and reverse the full amount, as well as
              create <i>another</i> copy on which the claim amounts can be
              changed.
            </Typography>
          </div>
        </DialogContent>
      </Dialog>
    </>
  )
}
