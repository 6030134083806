import React, { useEffect, useState } from 'react'
import { Button } from '@material-ui/core'
import * as api from '../../../../services/thezerocard/api-helper'
import useErrorHandlers from '../../../../hooks/useErrorHandlers'
import useSnackbar, { SnackbarTypeSuccess } from '../../../../hooks/useSnackbar'
import DesignSuite2023 from '../../../../components/DesignSuite2023'

export default function WorkflowUtils(): React.ReactElement | null {
  const [isProcessingVBA, setIsProcessingVBA] = useState(false)
  const { catchAPIError } = useErrorHandlers()
  const { showForDuration: showSnackbar } = useSnackbar()

  useEffect(() => {
    setIsProcessingVBA(false)
  }, [])

  function send(action: string): () => void {
    return () => {
      api
        .post('/zclaims/tools/misc', { action })
        .then((res: any) => {
          if (res.error) throw res
          showSnackbar(`${action} OK`, SnackbarTypeSuccess)
        })
        .catch(
          catchAPIError({
            defaultMessage: `Failed ${action}`,
          })
        )
    }
  }

  function onClickVBASetup() {
    if (isProcessingVBA) return
    // @ts-ignore
    if (
      window.confirm(
        'You sure? Check the logs in AWS cloudwatch for progress...'
      )
    ) {
      setIsProcessingVBA(true)
      api
        .post(`/zclaims/tools/process_vba`)
        .then((res: any) => {
          if (res.error) throw res
          showSnackbar(
            // `VBA backfilled OK (${res.Data?.Duration})`,
            `VBA backfill triggered (running in background; check logs associated with files above to determine success)`,
            SnackbarTypeSuccess,
            4000
          )
        })
        .catch(catchAPIError({ defaultMessage: 'SourceFile ingestion failed' }))
        .finally(() => {
          setIsProcessingVBA(false)
        })
    }
  }

  return (
    <div
      style={{
        display: 'grid',
        gridTemplateColumns: 'max-content 1fr',
        gridRowGap: '1rem',
        gridColumnGap: '0.5rem',
        alignItems: 'center',
      }}>
      <Button
        variant="outlined"
        size="small"
        onClick={send('recompute_summary')}>
        Rerun All Computed Summaries
      </Button>
      <span>
        Trigger recomputing all <code>zclaim_computed_summary</code> values.
      </span>

      <Button variant="outlined" size="small" onClick={send('recompute_dupes')}>
        Recompute All Duplicates
      </Button>
      <span>
        Trigger rematching all <code>zclaim_related_claims</code> (duplicates).
      </span>

      <Button
        disabled={isProcessingVBA}
        size="small"
        variant="outlined"
        onClick={onClickVBASetup}>
        Setup VBA/Legacy Claims
        {isProcessingVBA && (
          <>
            &nbsp;&nbsp;
            <DesignSuite2023.LoadingSpinner size={15} />
          </>
        )}
      </Button>
      <span>
        Re-ingest latest VBA records.{' '}
        <strong>
          Check <code>Claim/Source Files</code> tab and look for{' '}
          <code>VBA (Claims and Lines)</code> source type
        </strong>
        .
      </span>
    </div>
  )
}
