import React, { useEffect, useState } from 'react'
import { getInvoiceCalcsForZClaim } from '../../../actions/ZClaimFundingActions'

import {
  Card,
  CardContent,
  CardHeader,
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core'
import useErrorHandlers from '../../../hooks/useErrorHandlers'

interface cost {
  Description: string
  EntityName: string
  BasisRate: string
  Basis: string
  Rate: string
  Amount: string
}

export interface zClaimInvoiceCalc {
  ZClaimID: number
  EmployerName: string
  PayableAmount: string
  RateService: string
  RateFarley: string
  FeeService: string
  FeeFarley: string
  Costs: cost[]
  TotalCost: string
  ClaimInvoiceTotal: string
  IsReversal: boolean
}

const defaultZClaimInvoiceCalc = {
  ZClaimID: 0,
  EmployerName: '',
  PayableAmount: '',
  RateService: '',
  RateFarley: '',
  FeeService: '',
  FeeFarley: '',
  Costs: [],
  TotalCost: '',
  ClaimInvoiceTotal: '',
  IsReversal: false,
}

interface props {
  zClaimID: number
  doClose?(): void
}

export function InvoiceClaimCalcsForClaim({
  zClaimID,
  doClose,
}: props): React.ReactElement {
  const { catchAPIError } = useErrorHandlers()
  const [data, setData] = useState<zClaimInvoiceCalc>(defaultZClaimInvoiceCalc)
  useEffect(() => {
    getData()
  }, [])

  function getData() {
    return getInvoiceCalcsForZClaim(zClaimID)
      .then((res: any) => {
        setData(res.Data)
      })
      .catch(
        catchAPIError({
          defaultMessage: 'Failed to get invoice calc data',
        })
      )
  }

  return <InvoiceClaimCalcs data={data} doClose={doClose} />
}

interface invoiceCalcProps {
  data: zClaimInvoiceCalc
  doClose?(): void
}

function InvoiceClaimCalcs({
  data,
  doClose,
}: invoiceCalcProps): React.ReactElement {
  if (!data) {
    return <></>
  }

  onclose = doClose ? doClose : null

  const RightAlignedCell = ({ children }: any) => (
    <TableCell style={{ textAlign: 'right' }}>{children}</TableCell>
  )

  return (
    <Container>
      <Card variant={'outlined'} style={{ marginTop: '20px' }}>
        <CardHeader
          title={
            <>
              <div>{`${data.EmployerName}`}</div>
              <div>{`[claim ${data.ZClaimID}]`}</div>
              {data.IsReversal && (
                <Typography color="error">Reversal</Typography>
              )}
            </>
          }></CardHeader>
        <CardContent>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <RightAlignedCell>Basis(%)</RightAlignedCell>
                  <RightAlignedCell>Basis($)</RightAlignedCell>
                  <RightAlignedCell>Rate(%)</RightAlignedCell>
                  <RightAlignedCell>Amount($)</RightAlignedCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>
                    <b>Invoice</b>
                  </TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                </TableRow>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell>Claim</TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <RightAlignedCell>
                    {formatAmount(data.PayableAmount)}
                  </RightAlignedCell>
                </TableRow>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell>ZERO Service</TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <RightAlignedCell>
                    {formatRate(data.RateService)}
                  </RightAlignedCell>
                  <RightAlignedCell>
                    {formatAmount(data.FeeService)}
                  </RightAlignedCell>
                </TableRow>
                {!data.FeeFarley || data.FeeFarley === '0' ? null : (
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell>Farley</TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <RightAlignedCell>
                      {formatRate(data.RateFarley)}
                    </RightAlignedCell>
                    <RightAlignedCell>
                      {formatAmount(data.FeeFarley)}
                    </RightAlignedCell>
                  </TableRow>
                )}
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell>
                    <b>Total invoice</b>
                  </TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <RightAlignedCell>
                    <b>{formatAmount(data.ClaimInvoiceTotal)}</b>
                  </RightAlignedCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <b>Cost</b>
                  </TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                </TableRow>
                {data.Costs.map((cost, index) => (
                  <TableRow key={index}>
                    <TableCell></TableCell>
                    <TableCell>
                      {cost.Description + ': ' + cost.EntityName}
                    </TableCell>
                    <RightAlignedCell>
                      {formatRate(cost.BasisRate)}
                    </RightAlignedCell>
                    <RightAlignedCell>
                      {formatAmount(cost.Basis)}
                    </RightAlignedCell>
                    <RightAlignedCell>{formatRate(cost.Rate)}</RightAlignedCell>
                    <RightAlignedCell>
                      {formatAmount(cost.Amount)}
                    </RightAlignedCell>
                  </TableRow>
                ))}
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell>
                    <b>Total cost</b>
                  </TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <RightAlignedCell>
                    <b>{formatAmount(data.TotalCost)}</b>
                  </RightAlignedCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </CardContent>
      </Card>
    </Container>
  )
}

function formatAmount(amount: string | number): string {
  if (!amount) {
    return `$0.00`
  }

  if (typeof amount === 'number') {
    amount = amount.toString()
  }

  if (amount === '0') {
    return `$0.00`
  }

  return `$${amount.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`
}

// formatRate expects a string of numbers formatted #.### and returns a
// string formatted ###.#%, without leading zeros
function formatRate(rate: string): string {
  if (rate.length < 4 || rate === '0.000' || !rate) {
    return '0.0%'
  }

  // remove decimals
  rate = rate.replace(/\./g, '')

  // place decimal point
  rate = rate.slice(0, -1) + '.' + rate.slice(-1)

  // remove leading zeros
  while (rate.startsWith('0')) {
    rate = rate.slice(1)
  }

  return `${rate}%`
}
