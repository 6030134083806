import React, { forwardRef, useContext, useState } from 'react'
import * as stdTableSetup from '../../../hooks/useStandardTableSetup'
import { findZClaimInvoiced } from '../../../actions/ZClaimFundingActions'
import { EmployerSearcher } from '../../../components/Searchers'
import {
  FormControl,
  FormControlLabel,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Switch,
} from '@material-ui/core'
import { AspectRatio as IconAspectRatio } from '@material-ui/icons'
import ViewInvoicedDetailOverlay from './ViewInvoicedDetailOverlay'
//@ts-ignore
import styled from 'styled-components'

const StyledTableWrap = styled.div`
  // data-table fixed height related styling
  &.full-fixed {
    &.zclaims-invoice-table-wrap,
    .std-table,
    .base-table-display {
      height: 100%;
    }

    .base-table-display {
      overflow: clip;
      overflow-clip-margin: 1rem;
    }

    .datatable-common-wrapper {
      height: calc(100% - 3rem);

      .MuiTableContainer-root {
        max-height: none;
        height: 100%;
      }
    }

    .std-table {
      &.has-before {
        display: flex;

        .lefty {
          position: sticky;
          top: 20px;
          align-self: start;
          padding-right: 1rem;
        }

        .base-table-display {
          margin-top: 0;
        }
      }
    }
  }

  .MuiIconButton-sizeSmall {
    padding: 7px;
    font-size: 1.3rem;
  }
`
export * from '../../../hooks/useStandardTableSetup'

export interface invoicedRecord {
  InvoiceID: number
  InvoiceNumber: string
  InvoiceDate: string
  EmployerID: number
  EmployerName: string
  ClaimCount: number
  InvoiceTotal: string
  AppliedReceipts: string
  InvoiceBalance: string
  BillingMethod: BillingMethod
  PaidStatus: string
}

enum BillingMethod {
  All = 'all',
  DebitApproval = 'debit_approval',
  DebitAuto = 'debit_auto',
  ACH = 'ach',
  Check = 'check',
}

function getBillingMethodDisplay(value: BillingMethod): string {
  switch (value) {
    case BillingMethod.All:
      return 'All'
    case BillingMethod.DebitAuto:
      return 'Debit Auto'
    case BillingMethod.ACH:
      return 'ACH'
    case BillingMethod.Check:
      return 'Check'
    default:
      return ''
  }
}

export const defaultColumns = Object.freeze({
  _ViewInvoiceDetails: {
    name: '',
    details: {
      dataFormat(_: any, row: invoicedRecord): any {
        return <InlineViewInvoiceDetail data={row} />
      },
    },
  },
  InvoiceNumber: {
    name: 'Number',
    details: {
      sortName: 'InvoiceNumber',
    },
  },
  InvoiceDate: { name: 'Date', details: { sortName: 'InvoiceDate' } },
  EmployerName: { name: 'Employer', details: { sortName: 'EmployerName' } },
  BillingMethod: {
    name: 'Method',
    details: {
      dataFormat(_: BillingMethod, row: invoicedRecord): string {
        return getBillingMethodDisplay(row.BillingMethod)
      },
    },
  },
  InvoiceTotal: {
    name: 'Invoiced',
    details: {
      dataFormat(_: any, row: invoicedRecord): string {
        return formatAmount(row.InvoiceTotal)
      },
      sortName: 'InvoiceAmount',
    },
  },
  AppliedReceipts: {
    name: 'Receipts',
    details: {
      dataFormat(_: any, row: invoicedRecord): string {
        return formatAmount(row.AppliedReceipts)
      },
    },
  },
  InvoiceBalance: {
    name: 'Balance',
    details: {
      dataFormat(_: any, row: invoicedRecord): string {
        return formatAmount(row.InvoiceBalance)
      },
    },
  },
  PaidStatus: {
    name: 'Status',
    details: { sortName: 'ReconciliationStatus' },
  },
})

const defaultSort = { col: 'InvoiceNumber', dir: 'desc' }

export const Table = forwardRef(function Table(
  {
    customColumns = defaultColumns,
    apiEndpoint = findZClaimInvoiced,
    DataTableProps = {},
    ...passThrough
  }: stdTableSetup.props & Partial<any>,
  ref: any
): React.ReactElement | null {
  const { TableDisplay } = stdTableSetup.useStandardTableSetup(
    {
      ...passThrough,
      customColumns,
      apiEndpoint,
      defaultSort,
      passFilters: { hasBalance: true },
      initPageSize: 100,
      DataTableProps: {
        isRowCheckable: (row: invoicedRecord) =>
          row.InvoiceBalance !== '0' &&
          row.BillingMethod === BillingMethod.DebitAuto,
        keyProp: 'InvoiceID',
        ...DataTableProps,
        rowsPerPage: [10, 25, 50, 100, 250, 500],
        density: 'small',
      },
    },
    ref
  )

  return (
    <StyledTableWrap
      className={`zclaims-invoice-table-wrap ${passThrough?.className || ''}`}>
      {TableDisplay}
    </StyledTableWrap>
  )
})

function formatAmount(amount: string): string {
  if (!amount || amount === '0') return '$0.00'
  return `$${amount.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`
}

function InlineViewInvoiceDetail({
  data,
}: {
  data: invoicedRecord
}): React.ReactElement {
  const { refresh } = useContext(stdTableSetup.baseContext)
  const ref = React.useRef<any>(null)

  return (
    <>
      <IconButton size="small" onClick={() => ref?.current?.open()}>
        <IconAspectRatio fontSize="inherit" />
      </IconButton>
      <ViewInvoicedDetailOverlay
        ref={ref}
        invoiceID={data.InvoiceID}
        onClose={() => refresh()}
      />
    </>
  )
}

export function FilterEmployer(props: any): React.ReactElement {
  const { filter, setFilterImmediate } = useContext(stdTableSetup.baseContext)

  return (
    <EmployerSearcher
      {...props}
      selectedEmployerID={filter.employerIDs?.[0]}
      onChange={(empID: number | null) => {
        if (!empID) {
          setFilterImmediate({ employerIDs: [] })
          return
        }
        setFilterImmediate({ employerIDs: [empID] })
      }}
      TextFieldProps={{
        variant: 'outlined',
        size: 'small',
        InputLabelProps: { shrink: true },
      }}
      AutoCompleteProps={{
        style: { display: 'inline-block', maxWidth: 350 },
      }}
    />
  )
}

export function FilterHasBalance(): React.ReactElement {
  const { filter, setFilterImmediate } = useContext(stdTableSetup.baseContext)

  return (
    <FormControlLabel
      label="Has Balance"
      control={
        <Switch
          color="primary"
          checked={filter.hasBalance}
          onChange={(e: any) => {
            if (e.target.checked) {
              setFilterImmediate({ ...filter, hasBalance: true })
              return
            }
            setFilterImmediate({ ...filter, hasBalance: null })
          }}
        />
      }
    />
  )
}

export function FilterBillingMethod(): React.ReactElement {
  const [internalID] = useState(`${Math.round(Math.random() * 10000000000000)}`)
  const { filter, setFilterImmediate } = useContext(stdTableSetup.baseContext)
  const [items] = useState(() => {
    return Object.values(BillingMethod).map((value: BillingMethod) => ({
      value,
      label: getBillingMethodDisplay(value),
    }))
  })

  const onChange = (e: any, sel: any) => {
    if (sel.props?.value === 'all') {
      return setFilterImmediate({ billingMethods: null })
    }
    setFilterImmediate({ billingMethods: [sel.props.value] })
  }

  const v = filter.billingMethods || BillingMethod.All

  return (
    <FormControl
      variant="outlined"
      margin="none"
      size="small"
      fullWidth
      style={{ minWidth: 170 }}>
      <InputLabel shrink id={internalID}>
        Biling Method
      </InputLabel>
      <Select
        labelId={internalID}
        value={v}
        onChange={onChange}
        label="Biling Method"
        autoWidth>
        {items.map((item: any) => (
          <MenuItem key={item.label} value={item.value}>
            {item.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}
