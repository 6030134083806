import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import {
  Paper as MUIPaper,
  Grid as MUIGrid,
  Typography as MUITypography,
  Tabs as MUITabs,
  Tab as MUITab,
  Tooltip as MUITooltip,
  TableContainer as MUITableContainer,
  Table as MUITable,
  CircularProgress as MUICircularProgress,
  Link as MUILink,
  Card as MUICard,
  CardHeader as MUICardHeader,
  CardContent as MUICardContent,
  Button as MUIButton,
  Drawer as MUIDrawer,
  DrawerProps as MUIDrawerProps,
} from '@material-ui/core'
import { Alert as MUIAlert } from '@material-ui/lab'
import {
  Add as IconAdd,
  AttachMoney as IconAttachMoney,
  Block as IconDeny,
  Cancel as IconCancel,
  CheckBox as IconCheckBox,
  Description as IconFile,
  Done as IconCheck,
  Edit as IconEdit,
  Folder as IconFolder,
  Info as IconInfo,
  KeyboardArrowDown as IconArrowDown,
  KeyboardArrowLeft as IconArrowLeft,
  KeyboardArrowRight as IconArrowRight,
  KeyboardArrowUp as IconArrowUp,
  Lock as IconLock,
  Map as IconMap,
  OpenInNew as IconOpenInNew,
  Refresh as IconRefresh,
  Room as IconMapPin,
  Save as IconSave,
  Search as IconSearch,
  Undo as IconUndo,
  Visibility as IconPeek,
  Warning as IconWarning, // triangle exclamation alert icon
} from '@material-ui/icons'
import dateTime from '../../utils/dateTime'
import styled from 'styled-components'
require('./style.scss')

export const SubSection = styled(
  ({ children, title, subtitle, ...spread }: any) => (
    <MUICard {...spread} variant="outlined">
      <MUICardHeader
        title={title}
        subheader={subtitle}
        style={{ paddingBottom: '8px' }}
      />
      <MUICardContent style={{ paddingTop: '0px' }}>{children}</MUICardContent>
    </MUICard>
  )
)`
  margin-bottom: 8px;
`

export const Section = styled(({ children, ...spread }: any) => (
  <MUIPaper {...spread} elevation={3}>
    {children}
  </MUIPaper>
))`
  padding: 1rem;
  overflow: hidden;
`

export const LayoutContainer = styled.div`
  display: flex;
  margin-bottom: 1.5rem;
`

export const LayoutPrimary = styled(
  ({ children, large = false, className = '', ...spread }: any) => (
    <Section className={`ds23-layout-primary ${className}`} {...spread}>
      {children}
    </Section>
  )
)`
  max-width: ${(props: any) => (props.large ? '1200px;' : '860px;')};
  margin-right: 1rem;
`

const LayoutSidebarContainer = styled.div<{ width?: string }>`
  width: ${(props: any) => props.width || '200px'};
  z-index: 99;
  padding-bottom: 1.5rem;
`

const LayoutSidebarContent = styled.div<{ stickyTop?: string | boolean }>`
  ${(props: any) =>
    !!props.stickyTop &&
    `
    position: sticky; 
    top: ${props.stickyTop === true ? 'calc(60px + 1rem)' : props.stickyTop};
  `}

  .MuiButton-root {
    margin-bottom: 0.5rem;
    &:last-of-type {
      margin-bottom: 0;
    }
  }
`

export const LayoutSidebar = ({
  children,
  width = '200px',
  stickyTop = 'calc(60px + 1rem)',
  className = '',
  ...remaining
}: any) => (
  <LayoutSidebarContainer
    width={width}
    className={`ds23-layout-sidebar ${className}`}
    {...remaining}>
    <LayoutSidebarContent stickyTop={stickyTop}>
      {children}
    </LayoutSidebarContent>
  </LayoutSidebarContainer>
)

export const Table = ({ children, ...spread }: any) => (
  <MUITableContainer component={MUIPaper}>
    <MUITable size="small" {...spread}>
      {children}
    </MUITable>
  </MUITableContainer>
)

export const StyledTabs = styled(
  ({ children, transparent = false, marginless = false, ...spread }: any) => (
    <MUITabs textColor="inherit" indicatorColor="primary" {...spread}>
      {children}
    </MUITabs>
  )
)`
  background: ${(props: any) =>
    props.transparent ? 'transparent' : '#e0e0e0'};
  margin: ${(props: any) => (props.marginless ? '0' : '0 -1rem 1rem')};
  // background: #e0e0e0;
  // margin: -1rem -1rem 1rem;
`

export const StyledTab = styled(({ children, ...spread }: any) => (
  <MUITab {...spread} textColor="inherit">
    {children}
  </MUITab>
))``

export const Divider = styled.hr`
  border: none;
  height: 1px;
  margin: 1rem 0;
  background-color: rgba(0, 0, 0, 0.12);
`

export const DisplayRecordMeta = styled(
  ({ record, children, ...spread }: any) => {
    if (!record) return null

    function ShowDate({ value }: any): React.ReactElement | any {
      const x = dateTime.parse(value).local()
      return x.isValid() ? (
        <span title={x.format(dateTime.formats.PrettyVerbose)}>
          {x.format(dateTime.formats.AmericanDate)}
        </span>
      ) : (
        'N/A'
      )
    }

    return (
      <MUITypography variant="body2" color="textSecondary" {...spread}>
        {record.CreatedAt && (
          <span>
            <strong>Created:</strong>
            <ShowDate value={record.CreatedAt} />
          </span>
        )}
        {record.UpdatedAt && (
          <span>
            <strong>Last Updated:</strong>
            <ShowDate value={record.UpdatedAt} />
          </span>
        )}
        {record.ModifiedByUsername && (
          <span>
            <strong>Last Modified By:</strong>
            {record.ModifiedByUsername}
          </span>
        )}
        {children}
      </MUITypography>
    )
  }
)`
  font-size: 90%;
  span {
    display: block;
    margin-bottom: 0.5rem;
    strong {
      display: block;
    }
  }
`

export const StyledAlertGroup = styled.div`
  font-size: 90%;

  .MuiAlert-root {
    margin-bottom: 0.5rem;
    font-size: inherit;
    box-shadow: 0 0 11px -3px rgba(0, 0, 0, 0.35);
    &:last-of-type {
      margin: 0;
    }
    ul {
      margin: 0;
      padding-left: 1rem;
    }
  }
`

export const AlertError = ({ children, ...spread }: any) => (
  <MUIAlert {...spread} severity="error">
    {children}
  </MUIAlert>
)

export const AlertWarning = ({ children, ...spread }: any) => (
  <MUIAlert {...spread} severity="warning">
    {children}
  </MUIAlert>
)

export const AlertSuccess = ({ children, ...spread }: any) => (
  <MUIAlert {...spread} severity="success">
    {children}
  </MUIAlert>
)

export const AlertInfo = ({ children, ...spread }: any) => (
  <MUIAlert {...spread} severity="info">
    {children}
  </MUIAlert>
)

export const LoadingSpinner = styled(({ ...spread }: any) => (
  <MUICircularProgress size={25} {...spread} />
))`
  margin: 0 auto;
`

const StyledTooltipContent = styled.div`
  font-size: 0.8rem;
  font-weight: 400;
  padding: 0.25rem;
  line-height: 1.5;
  max-width: min(35vw, 650px);
`

export const Tooltip = ({
  children,
  title,
  ContainerStyle = {},
  ...spread
}: any) => (
  <MUITooltip
    {...spread}
    arrow
    placement={spread?.placement || 'top'}
    PopperProps={{
      'data-zc-tooltip': 'true', // hack: see style.scss
    }}
    title={
      title ? (
        <StyledTooltipContent style={ContainerStyle}>
          {title}
        </StyledTooltipContent>
      ) : (
        ''
      )
    }>
    {children}
  </MUITooltip>
)

export const GridLR = ({
  left = null,
  right = null,
  ...spread
}: {
  left?: React.ReactElement | null | undefined
  right?: React.ReactElement | null | undefined
} & Partial<any>) => (
  <MUIGrid
    container
    spacing={2}
    justify="space-between"
    alignItems="center"
    {...spread}>
    <MUIGrid item xs="auto">
      {left}
    </MUIGrid>
    <MUIGrid item xs="auto">
      {right}
    </MUIGrid>
  </MUIGrid>
)

// https://v4.mui.com/guides/composition/#link
export const Link = React.forwardRef(
  ({ children, to, ...spread }: any, ref: any) => (
    <MUILink
      ref={ref}
      component={RouterLink}
      variant="inherit"
      underline="always"
      to={to}
      {...spread}>
      {children}
    </MUILink>
  )
)

export const StyledPre = styled.pre`
  background: #222;
  color: #fff;
  border: 0;
  margin: 0;
  font-size: 90%;
  white-space: break-spaces;
`

export const LoadingButton = ({
  children,
  loading = false,
  ...spread
}: {
  loading?: boolean | undefined
} & Partial<any>) => {
  const endIcon = loading ? <LoadingSpinner color="inherit" /> : null
  return (
    <MUIButton fullWidth endIcon={endIcon} {...spread}>
      {children}
    </MUIButton>
  )
}

export type DrawerProps = React.PropsWithChildren<
  Partial<Pick<MUIDrawerProps, 'variant' | 'anchor' | 'open'>> & {
    width?: string | number
    onClose?: Function // MUIDrawerProps has onClose too that could be poached with <Pick>, but its arg signature requires a value
    onOpen?: Function // *not* part of MUIDrawerProps, but makes sense as opening can be controlled via ref API
    disableEscapeKeyDown?: boolean
    paperTransparent?: boolean
  }
>

export const Drawer = React.forwardRef<DrawerProps, any>(function (
  {
    children,
    variant = 'temporary',
    anchor = 'right',
    width = 'auto',
    open: propOpen = false,
    onClose: propOnClose = () => {}, // noop default
    onOpen = () => {}, // noop default
    disableEscapeKeyDown = false,
    paperTransparent = false,
  }: DrawerProps,
  ref: any
): React.ReactElement {
  const [isOpen, setIsOpen] = React.useState(propOpen)
  const otherProps = React.useMemo(() => {
    const paperStyle = paperTransparent
      ? {
          backgroundColor: 'transparent',
          color: '#fff',
          border: 'none',
        }
      : null

    return {
      PaperProps: {
        style: { ...paperStyle, width },
      },
      ModalProps: {
        disableEscapeKeyDown,
        BackdropProps: {
          style: {
            backgroundColor: 'rgba(0,0,0,0.75)',
            backdropFilter: 'blur(5px)',
          },
        },
      },
    }
  }, [paperTransparent, width, disableEscapeKeyDown])

  React.useEffect(() => {
    setIsOpen(propOpen)
  }, [propOpen])

  React.useEffect(() => {
    if (!isOpen) return
    onOpen?.()
  }, [isOpen])

  React.useImperativeHandle(
    ref,
    () => ({
      open: () => setIsOpen(true),
      close: () => setIsOpen(false),
    }),
    [setIsOpen]
  )

  function onClose() {
    propOnClose?.()
    setIsOpen(false)
  }

  return (
    <React.Fragment>
      <MUIDrawer
        {...otherProps}
        open={isOpen}
        anchor={anchor}
        variant={variant}
        onClose={onClose}>
        {children}
      </MUIDrawer>
    </React.Fragment>
  )
})

export const CommonIcons = {
  IconAdd,
  IconArrowDown,
  IconArrowLeft,
  IconArrowRight,
  IconArrowUp,
  IconAttachMoney,
  IconCancel,
  IconCheck,
  IconCheckBox,
  IconDeny,
  IconEdit,
  IconFile,
  IconFolder,
  IconInfo,
  IconLock,
  IconMapPin,
  IconOpenInNew,
  IconPeek,
  IconRefresh,
  IconSave,
  IconSearch,
  IconMap,
  IconUndo,
  IconWarning,
}

export default {
  LayoutContainer,
  LayoutPrimary,
  LayoutSidebar,
  SubSection,
  Section,
  Table,
  StyledTabs,
  StyledTab,
  Divider,
  DisplayRecordMeta,
  StyledAlertGroup,
  AlertError,
  AlertWarning,
  AlertSuccess,
  AlertInfo,
  CommonIcons,
  Tooltip,
  GridLR,
  LoadingSpinner,
  Link,
  StyledPre,
  LoadingButton,
  Drawer,
}
