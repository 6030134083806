import React, { useState } from 'react'
import useSnackbar, { SnackbarTypeSuccess } from '../../../hooks/useSnackbar'
import useErrorHandlers from '../../../hooks/useErrorHandlers'
import { postInvoice } from '../../../actions/ZClaimFundingActions'
import DesignSuite2023 from '../../../components/DesignSuite2023'
import { Button } from '@material-ui/core'

interface props {
  zClaimIDs: number[]
  invoiceCount: number
  onComplete?(): void
  disabled?: boolean
}

export default React.forwardRef(function ButtonDoInvoice(
  { zClaimIDs, invoiceCount, onComplete, disabled = false }: props,
  ref: any
): React.ReactElement {
  const [isWorking, setIsWorking] = useState(false)
  const { showForDuration: showSnackbar } = useSnackbar()
  const { catchAPIError } = useErrorHandlers()

  function doInvoice() {
    setIsWorking(true)

    return postInvoice({ ZClaimIDs: zClaimIDs })
      .then(() => {
        showSnackbar('Invoice completed OK', SnackbarTypeSuccess)
        onComplete?.()
      })
      .catch(
        catchAPIError({
          defaultMessage: 'Failed Invoice',
        })
      )
      .finally(() => setIsWorking(false))
  }

  return (
    <DesignSuite2023.Tooltip title="Invoice selected items">
      <span>
        <Button
          ref={ref}
          disabled={isWorking || disabled}
          fullWidth
          variant="outlined"
          color="secondary"
          size="small"
          endIcon={<DesignSuite2023.CommonIcons.IconFile />}
          onClick={doInvoice}>
          {'Invoice' + (invoiceCount > 0 ? ` (${invoiceCount})` : '')}
        </Button>
      </span>
    </DesignSuite2023.Tooltip>
  )
})
