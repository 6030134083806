import {
  getEmployerReceiptDetail,
  PostApplyEmployerReceiptRecord,
  PostApplyEmployerReceiptsParams,
  postApplyEmployerReceipts,
  receiptType,
} from '../../../actions/ZClaimFundingActions'
import React, { useEffect, useState } from 'react'
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Chip,
  Container,
  Divider,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core'
import { RenderPriceField, SetterArgs } from '../../ViewHelpers'
import useSnackbar, { SnackbarTypeSuccess } from '../../../hooks/useSnackbar'
import useErrorHandlers from '../../../hooks/useErrorHandlers'
import styled from 'styled-components'

interface appliedEmployerReceipt {
  ReceiptInvoiceID: string
  ReceiptID: number
  ReceiptDate: Date
  ReceiptType: receiptType
  CheckNumber: string
  ReceiptAmount: string
  AppliedAmount: string
  Notes: string
}

interface relatedInvoiceInfo {
  InvoiceID: number
  InvoiceNumber: number
  InvoiceDate: Date
  InvoiceAmount: string
  AppliedAmount: string
  OpenBalance: string
  AppliedReceipts: appliedEmployerReceipt[]
}

interface employerReceiptInfo {
  ReceiptID: number
  ReceiptDate: Date
  EmployerID: number
  EmployerName: string
  ReceiptType: receiptType
  CheckNumber: string
  Notes: string
  ReceiptAmount: string
  AppliedAmount: string
  UnappliedAmount: string
  RelatedInvoiceInfo: relatedInvoiceInfo[]
}

const initialEmployerReceiptInfoRecord: employerReceiptInfo = {
  ReceiptID: 0,
  ReceiptDate: new Date(),
  EmployerID: 0,
  EmployerName: '',
  ReceiptType: receiptType.CHECK,
  CheckNumber: '',
  Notes: '',
  ReceiptAmount: '',
  AppliedAmount: '',
  UnappliedAmount: '',
  RelatedInvoiceInfo: [],
}

interface receiptDetailProps {
  receiptID: number
  doClose?(): void
}

const ReceiptDetailDiv = styled.div`
  margin-top: 8px;
  margin-bottom: 16px;
`

export default function ReceiptDetail({
  receiptID,
  doClose,
}: receiptDetailProps): React.ReactElement {
  onclose = doClose ? doClose : null
  const { catchAPIError } = useErrorHandlers()
  const { showForDuration: showSnackbar } = useSnackbar()
  const [data, setData] = useState<employerReceiptInfo>(
    initialEmployerReceiptInfoRecord
  )

  useEffect(() => {
    getData()
  }, [receiptID])

  function getData() {
    if (receiptID === 0) {
      setData(initialEmployerReceiptInfoRecord)
      return
    }
    return getEmployerReceiptDetail(receiptID)
      .then((res: any) => {
        setData(res.Data)
      })
      .catch(
        catchAPIError({
          defaultMessage: 'Failed to get invoice receipt data',
        })
      )
  }

  // map by invoiceID
  const [postApplyEmployerReceiptRecords, setPostApplyEmployerReceiptRecords] =
    useState<Map<number, PostApplyEmployerReceiptRecord>>(new Map())

  function doPostApplyReceipt(invoiceID: number) {
    // Get the record for the given invoiceID
    const record = postApplyEmployerReceiptRecords.get(invoiceID)

    // If there's no record for the given invoiceID, we can't proceed
    if (!record) {
      console.error(`No record found for invoiceID: ${invoiceID}`)
      return
    }

    // Create an array with the single record
    const params: PostApplyEmployerReceiptsParams = [record]

    postApplyEmployerReceipts(params)
      .then(() => {
        setAmountByInvoiceID({ value: '', invoiceID })
        getData()
        showSnackbar(`Receipt posted OK`, SnackbarTypeSuccess)
      })
      .catch(
        catchAPIError({
          defaultMessage: 'Failed posting receipt',
        })
      )
  }

  function getReceiptInfo() {
    if (data.ReceiptType === receiptType.CHECK) {
      return `Check Number: ${data.CheckNumber}`
    } else if (data.ReceiptType === receiptType.ACH) {
      return 'ACH'
    }
  }

  interface setByInvoiceIDArgs {
    value: string
    invoiceID: number
  }

  const setAmountByInvoiceID = ({ value, invoiceID }: setByInvoiceIDArgs) => {
    setPostApplyEmployerReceiptRecords((prevRecords) => {
      let record = prevRecords.get(invoiceID)
      if (record) {
        // Update the existing record
        record = {
          ...record,
          amount: value,
        }
      } else {
        // Create a new record
        record = {
          receiptID: data.ReceiptID,
          zClaimInvoiceID: invoiceID,
          date: new Date(),
          amount: value,
        }
      }
      prevRecords.set(invoiceID, record)

      // Create a new Map for the updated records
      const updatedRecords = new Map(prevRecords)
      updatedRecords.set(invoiceID, record)

      return updatedRecords
    })
  }

  return (
    <>
      <Container>
        <Typography component="div" variant={'subtitle1'}>
          Receipt [ID:{data.ReceiptID}]: {data.EmployerName}
        </Typography>
        <ReceiptDetailDiv>
          <Chip label="Method" size="small" />
          &nbsp;&nbsp;&nbsp;
          <Typography component="span" variant={'body1'}>
            {getReceiptInfo()}
          </Typography>
          &nbsp;&nbsp;&nbsp;
          <Chip label="Receipt Amount" size="small" />
          &nbsp;&nbsp;&nbsp;
          <Typography component="span" variant={'body1'}>
            {formatAmount(data.ReceiptAmount)}
          </Typography>
          &nbsp;&nbsp;&nbsp;
          <Chip label="Applied Amount" size="small" />
          &nbsp;&nbsp;&nbsp;
          <Typography component="span" variant={'body1'}>
            {formatAmount(data.AppliedAmount)}
          </Typography>
          &nbsp;&nbsp;&nbsp;
          <Chip label="Unapplied Balance" size="small" />
          &nbsp;&nbsp;&nbsp;
          <Typography component="span" variant={'body1'}>
            {formatAmount(data.UnappliedAmount)}
          </Typography>
          &nbsp;&nbsp;&nbsp;
        </ReceiptDetailDiv>
        <Typography component="div" variant={'body1'}>
          {data.Notes && `Notes: ${data.Notes}`}
        </Typography>
        <Divider />
        <Typography variant={'body1'}>
          Open and previously applied invoices:
        </Typography>
        {data.RelatedInvoiceInfo.length === 0 && (
          <Typography
            variant="h6"
            color={'textSecondary'}
            style={{ marginTop: '100px', marginBottom: '100px' }}>
            There are no open invoices for {data.EmployerName}
          </Typography>
        )}
        {data.RelatedInvoiceInfo.map((invoice) => {
          const setter = ({ value }: SetterArgs) => {
            setAmountByInvoiceID({ value, invoiceID: invoice.InvoiceID })
          }

          const getAmountValue = () => {
            const record = postApplyEmployerReceiptRecords.get(
              invoice.InvoiceID
            )
            return record ? record.amount : ''
          }

          return (
            <>
              <Card variant={'outlined'} style={{ marginTop: '20px' }}>
                <CardHeader
                  title={`Invoice Number: ${invoice.InvoiceNumber}`}
                  subheader={
                    <>
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <Typography variant="body1">
                            Invoice Date: {invoice.InvoiceDate}
                          </Typography>
                          <Typography variant="body1">
                            Invoice Total: {formatAmount(invoice.InvoiceAmount)}
                          </Typography>
                          <Typography variant="body1">
                            Applied Amount:{' '}
                            {formatAmount(invoice.AppliedAmount)}
                          </Typography>
                          <Typography variant="body1">
                            Open Balance: {formatAmount(invoice.OpenBalance)}
                          </Typography>
                        </Grid>
                        <Grid item xs={3}>
                          {invoice.OpenBalance !== '0' &&
                            data.UnappliedAmount !== '0' && (
                              <>
                                <RenderPriceField
                                  name="amount"
                                  label="Amount"
                                  opts={{
                                    placeholder: 'Enter amount to apply',
                                  }}
                                  value={getAmountValue()}
                                  setter={setter}
                                  use2023Styles
                                />
                                <Button
                                  color="primary"
                                  variant="outlined"
                                  fullWidth
                                  onClick={() =>
                                    doPostApplyReceipt(invoice.InvoiceID)
                                  }>
                                  Apply Receipt
                                </Button>
                              </>
                            )}
                        </Grid>
                      </Grid>
                    </>
                  }></CardHeader>
                <CardContent>
                  {invoice.AppliedReceipts &&
                    invoice.AppliedReceipts.length > 0 && (
                      <TableContainer>
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell>ID</TableCell>
                              <TableCell>Receipt ID</TableCell>
                              <TableCell>Receipt Date</TableCell>
                              <TableCell>Receipt Type</TableCell>
                              <TableCell>Check Number</TableCell>
                              <TableCell>Notes</TableCell>
                              <TableCell>Receipt Amount</TableCell>
                              <TableCell>Applied Amount</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {invoice.AppliedReceipts.map((receipt) => (
                              <TableRow
                                key={receipt.ReceiptInvoiceID}
                                style={
                                  receipt.ReceiptID === receiptID
                                    ? { backgroundColor: 'yellow' }
                                    : {}
                                }>
                                <TableCell>
                                  {receipt.ReceiptInvoiceID}
                                </TableCell>
                                <TableCell>{receipt.ReceiptID}</TableCell>
                                <TableCell>{receipt.ReceiptDate}</TableCell>
                                <TableCell>{receipt.ReceiptType}</TableCell>
                                <TableCell>{receipt.CheckNumber}</TableCell>
                                <TableCell>{receipt.Notes}</TableCell>
                                <TableCell>
                                  {formatAmount(receipt.ReceiptAmount)}
                                </TableCell>
                                <TableCell>
                                  {formatAmount(receipt.AppliedAmount)}
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    )}
                  <Divider />
                </CardContent>
              </Card>
            </>
          )
        })}
      </Container>
    </>
  )
}

function formatAmount(amount: string | number): string {
  if (!amount) {
    return `$0.00`
  }

  if (typeof amount === 'number') {
    amount = amount.toString()
  }

  if (amount === '0') {
    return `$0.00`
  }

  return `$${amount.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`
}
