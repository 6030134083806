import React, { useRef, useState } from 'react'
import * as InvoiceableTable from '../Components/InvoiceableTable'
import { invoiceableRecord } from '../Components/InvoiceableTable'
import { IconButton } from '@material-ui/core'
import DesignSuite2023 from '../../../components/DesignSuite2023'
import ButtonDoInvoice from '../Components/ButtonDoInvoice'

export default function PageInvoiceable(): React.ReactElement {
  const [checkedItems, setCheckedItems] = useState<Array<invoiceableRecord>>([])
  const refInvoiceableTable = useRef<any>(null)

  const pageColumnLeft = (
    <div className="lefty">
      <div style={{ paddingBottom: '1rem', textAlign: 'right' }}>
        <IconButton
          size="small"
          onClick={() => {
            refInvoiceableTable.current?.refresh()
          }}>
          <DesignSuite2023.CommonIcons.IconRefresh />
        </IconButton>
      </div>
      <div style={{ paddingBottom: '1rem' }}>
        <ButtonDoInvoice
          disabled={(checkedItems || []).length === 0}
          zClaimIDs={checkedItems
            .map((v: invoiceableRecord) => v.ZClaimIDs)
            .flat()}
          invoiceCount={checkedItems.length}
          onComplete={() => {
            refInvoiceableTable.current?.refresh()
          }}
        />
      </div>
      <DesignSuite2023.Divider />
      <div style={{ paddingBottom: '1rem' }}>
        <InvoiceableTable.StandardFilterSearch
          autoFocus
          label="Search"
          InputLabelProps={{ shrink: true }}
        />
      </div>
      <div style={{ paddingBottom: '1rem' }}>
        <InvoiceableTable.FilterEmployer />
      </div>
    </div>
  )

  return (
    <InvoiceableTable.Table
      ref={refInvoiceableTable}
      className="full-fixed"
      enableURLReflection="zclaims.invoiceable.list"
      onCheckHandler={setCheckedItems}
      DataTableProps={{ stickyHeader: true }}
      BeforeTable={pageColumnLeft}
    />
  )
}
