import React from 'react'
import {
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core'

export interface paidRecordDetail {
  LineNumber: number
  ZClaimID: number
  ZClaimAmount: string
  IsReversal: boolean
}

export interface paidRecord {
  PaymentID: string
  PaymentType: string
  Date: Date
  OrganizationID: number
  PartnerID: number
  PayeeName: string
  CheckingAccount: string
  ManualPayType: string
  CheckNumber: number
  Notes: string
  ClaimCount: number
  Amount: string

  Details: paidRecordDetail[]
}

interface paidDetailProps {
  data: paidRecord
  doClose?(): void
}

export default function PaidDetail({
  data,
  doClose,
}: paidDetailProps): React.ReactElement {
  onclose = doClose ? doClose : null
  return (
    <Container>
      <Typography variant="body1">Payment ID: {data.PaymentID}</Typography>
      <Typography variant="body1">Payee Name: {data.PayeeName}</Typography>
      <Typography variant="body1">Date: {data.Date.toString()}</Typography>
      <Typography variant="body1">
        Amount: {formatAmount(data.Amount)}
      </Typography>
      <Typography variant="body1">Payment Type: {data.PaymentType}</Typography>
      {data.ManualPayType && (
        <Typography variant="body1">
          Manual Payment Type: {data.ManualPayType}
        </Typography>
      )}
      <Typography variant="body1">
        Checking Account: {data.CheckingAccount}
      </Typography>
      {data.CheckNumber && (
        <Typography variant="body1">
          Check Number: {data.CheckNumber}
        </Typography>
      )}
      {data.Notes && (
        <Typography variant="body1">Notes: {data.Notes}</Typography>
      )}

      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Line Number</TableCell>
              <TableCell>ZClaim ID</TableCell>
              <TableCell>Amount</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.Details.map((detail, index) => (
              <TableRow key={index}>
                <TableCell>{detail.LineNumber}</TableCell>
                <TableCell>
                  {detail.ZClaimID}
                  {detail.IsReversal ? ' [reversal]' : ''}
                </TableCell>
                <TableCell>{formatAmount(detail.ZClaimAmount)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  )
}

function formatAmount(amount: string): string {
  if (!amount || amount === '0') return '$0.00'
  return `$${amount.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`
}
