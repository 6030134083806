import React from 'react'
import * as ClaimTable from '../../Components/ClaimTable'
import { useFrameHeightLock } from '../../../../components/AppFrame'

export default function WorkflowSuspenseQueue(): React.ReactElement {
  useFrameHeightLock(() => true, [])

  return (
    <ClaimTable.Table
      className="full-fixed"
      passFilters={{ InSuspense: true }}
      enableURLReflection="zclaims.workflows.suspenseQueue"
      DataTableProps={{
        stickyHeader: true,
      }}
    />
  )
}
